/* eslint-disable no-useless-escape */
import {endOfDay, isAfter, isToday} from 'date-fns';
import * as yup from 'yup';
import {statesForValidation} from '../components/common/StateDropdown/StateDropdown';
import {IndexedSchema, SchemaIndexMap} from '../types/yup';

export const phoneValidationMessage = 'Enter as (###) ###-####';
export const areaCodeMessage = 'Area code cannot start with 0 or 1';
export const zipCodeValidationMessage = 'Enter a valid zip code';
export const emailValidationMessage =
  'Invalid email address, enter as example@example.com and limit it to 60 characters,';
export const mileageValidationMessage = 'Enter a valid mileage';
export const cityValidationMessage = 'Enter a valid city';
export const streetAddressValidationMessage = 'Street address cannot exceed 60 characters';
export const firstNameMinValidationMessage = 'First Name must be at least 2 characters';
export const firstNameMaxValidationMessage = 'First Name cannot exceed 30 characters';
export const lastNameMinValidationMessage = 'Last Name must be at least 2 characters';
export const lastNameMaxValidationMessage = 'Last Name cannot exceed 30 characters';

// CUSTOM YUP VALIDATION METHODS
yup.addMethod<yup.StringSchema>(yup.string, 'year', function (message: string) {
  return this.test({
    test: (value: any) => {
      const numericValue = parseInt(value);
      if (value) {
        return value.length === 4 && numericValue >= 1901 && numericValue <= 2999;
      } else {
        return true;
      }
    },
    message,
  });
});

yup.addMethod<yup.DateSchema>(yup.date, 'futureDate', function (message: string) {
  return this.test({
    test: (value: any) => {
      return isAfter(new Date(), new Date(value));
    },
    message,
  });
});

yup.addMethod<yup.DateSchema>(yup.date, 'isBusinessDay', function (message: string) {
  return this.test({
    test: (value: any) => {
      return ![0, 6].includes(new Date(value).getDay());
    },
    message,
  });
});

const validateChild = async (
  indexedSchema: IndexedSchema,
  path: string,
  value: any,
  parentValue: any,
  returnErrors: any[] = [],
  messages: string[] = []
) => {
  try {
    await indexedSchema._schema.validate(value, {context: {parentValue}});
    const objKeys = Object.keys(indexedSchema);
    const children = objKeys.filter((keyName: string) => !['_schema'].includes(keyName));

    for (let i: number = 0; i < children.length; i++) {
      const key = children[i];
      const childPath = /^\d+$/.test(key) ? `[${key}]` : `.${key}`;

      await validateChild(
        {...indexedSchema[key]},
        path + childPath,
        value[key],
        value,
        returnErrors,
        messages
      );
    }
  } catch (error) {
    (error as any).path = path;
    (error as any).params.path = path;
    returnErrors.push(error);
    messages.push((error as any)?.errors?.[0]);
  }

  return {returnErrors, messages};
};

yup.addMethod<yup.ArraySchema<any>>(
  yup.array,
  'specifyIndeces',
  function (indexedSchemas: SchemaIndexMap, defaultSchema: IndexedSchema) {
    return this.test({
      test: async function (value: any[] | undefined = []) {
        let success: any = true;
        let validationError: any = this.createError();

        for (let i: number = 0; i < value.length; i++) {
          const schemaToUse = indexedSchemas[i] || defaultSchema;
          const {returnErrors, messages} = await validateChild(
            schemaToUse,
            `${this.path}[${i}]`,
            value[i],
            value,
            [],
            []
          );

          if (returnErrors?.length) {
            success = false;
            validationError.inner.push(...returnErrors);
          }
          if (messages?.length) {
            success = false;
            validationError.errors.push(...messages);
          }
        }

        return success || validationError;
      },
    });
  }
);
// END CUSTOM YUP VALIDATION METHODS

export const numbersOnlyValidation = (message: string, excludeEmptyString: boolean) => {
  return yup.string().matches(/^\d*$/, {message, excludeEmptyString});
};

export const autoPolicyNumberValidation = (excludeEmptyString: boolean) => {
  return yup
    .string()
    .matches(/(^[a-zA-z]{3}[\d]{7}$)|(^[\d]{10}$)|(^[\d]{8}[a-zA-z]{2}$)/, {
      message: ({value}) => {
        if (/^[a-zA-z]+/.test(value)) {
          return 'Invalid policy number format: Number must have 3 letters and 7 digits.';
        } else if (/^[\d]+[a-zA-z]+/.test(value)) {
          return 'Invalid policy number format: Number must have 8 digits and 2 letters.';
        } else if (/^[\d]+/.test(value)) {
          return 'Invalid policy number format: Number must have 10 digits.';
        } else {
          return 'Invalid policy number format.';
        }
      },
      excludeEmptyString,
    })
    .required('Please enter a valid policy number.');
};

export const homeownersPolicyNumberValidation = (excludeEmptyString: boolean) => {
  return yup
    .string()
    .matches(/(^([a-zA-z]{3}|[a-zA-z]{2}(\s+)?)[\d]{7}$)|(^[\d]{10}$)|(^[\d]{8}[a-zA-z]{2}$)/, {
      message: ({value}) => {
        if (/^[a-zA-z]+/.test(value)) {
          return 'Invalid policy number format: Number must have 2 or 3 letters and 7 digits.';
        } else if (/^[\d]+[a-zA-z]+/.test(value)) {
          return 'Invalid policy number format: Number must have 8 digits and 2 letters.';
        } else if (/^[\d]+/.test(value)) {
          return 'Invalid policy number format: Number must have 10 digits.';
        } else {
          return 'Invalid policy number format.';
        }
      },
      excludeEmptyString,
    })
    .required('Please enter a valid policy number.');
};

export const noSpecialCharacters = (message: string, excludeEmptyString: boolean) => {
  return yup.string().matches(/^[\d\w\s]+$/, {message, excludeEmptyString});
};

export const vinValidation = (message: string, excludeEmptyString: boolean) => {
  return yup.string().matches(/^[(\d)|(_A-z)]{17}$/, {message, excludeEmptyString});
};

export const lettersOnlyValidation = (message: string, excludeEmptyString: boolean) => {
  return yup.string().matches(/^[A-z]*$/, {message, excludeEmptyString});
};

export const lettersAndSpacesValidation = (message: string, excludeEmptyString: boolean) => {
  return yup.string().matches(/^[a-zA-Z\s]+$/, {message, excludeEmptyString});
};

export const companyNameValidation = (message: string, excludeEmptyString: boolean) => {
  return yup
    .string()
    .matches(/^[A-z0-9\s\'\,\-]*$/, {message: message, excludeEmptyString: excludeEmptyString});
};

export const phoneValidationRegex = /^(\([2-9]\d{2}\))\s(\d{3})-(\d{4})$/;
export const phoneValidationWOBracketsRegex = /^[2-9]\d{9}$/;

export const phoneValidation = (excludeEmptyString: boolean) => {
  return yup.string().matches(phoneValidationRegex, {
    message: ({value}) => {
      if (value.includes('(0') || value.includes('(1')) {
        return areaCodeMessage;
      } else {
        return phoneValidationMessage;
      }
    },
    excludeEmptyString,
  });
};

export const zipCodeValidation = (excludeEmptyString: boolean) => {
  return yup.string().matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, {
    message: zipCodeValidationMessage,
    excludeEmptyString,
  });
};

export const emailValidation = (excludeEmptyString: boolean) => {
  return yup
    .string()
    .matches(
      /^(?![-._%+])[a-zA-Z0-9._%+-]+(?:[\\.||\\-][a-zA-Z0-9]+)*@[a-zA-Z0-9.-]+[\\.]+[a-zA-Z]{2,10}$/,
      {
        message: emailValidationMessage,
        excludeEmptyString,
      }
    )
    .max(60, emailValidationMessage);
};

export const timeValidation = (message: string, excludeEmptyString: boolean) => {
  return yup.string().matches(/\b((1[0-2]|0?[1-9]):([0-5][0-9]) ([AaPp][Mm]))/, {
    message,
    excludeEmptyString,
  });
};

export const mileageValidation = (excludeEmptyString: boolean) => {
  return yup.string().matches(/^(\d+|\d{1,3}(,\d{3})*)?$/, {
    message: mileageValidationMessage,
    excludeEmptyString,
  });
};

export const stateValidation = (message?: string) => {
  const validStateEntries = [...statesForValidation, ''];
  return yup.string().oneOf(validStateEntries, message || 'Enter a valid state');
};

export const cityValidation = (excludeEmptyString: boolean) => {
  return yup.string().matches(/^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/, {
    message: cityValidationMessage,
    excludeEmptyString,
  });
};

export const streetAddressValidation = (message?: string) => {
  return yup.string().max(60, message || streetAddressValidationMessage);
};

export const firstNameValidation = (message?: string) => {
  return yup
    .string()
    .min(2, message || firstNameMinValidationMessage)
    .max(30, message || firstNameMaxValidationMessage);
};

export const lastNameValidation = (message?: string) => {
  return yup
    .string()
    .min(2, message || lastNameMinValidationMessage)
    .max(30, message || lastNameMaxValidationMessage);
};

export const propertyDamageDescriptionValidation = () => {
  return yup.string().max(280, 'Character limit exceeded');
};

export const OptionalPhoneObjectSchema = yup
  .object()
  .shape({
    phoneNumber: phoneValidation(true).nullable(),
    phoneType: yup.string().when('phoneNumber', {
      is: (value: string) => !!value,
      then: yup.string().required('Phone Type is required'),
      otherwise: yup.string().nullable(),
    }),
    phoneExtension: yup.string().when('phoneNumber', {
      is: (value: any) => !!value,
      then: yup
        .string()
        .matches(/^\d{0,4}$/, 'Phone extension must be a numeric value of up to 4 digits.')
        .nullable(),
      otherwise: yup
        .string()
        .test(
          'not-allowed',
          'Phone extension should be empty if no phone number provided.',
          function (value) {
            return !value;
          }
        )
        .nullable(),
    }),
  })
  .nullable();

export const OptionalPhoneNumbersSchema = {
  phoneNumbers: yup.array().of(OptionalPhoneObjectSchema),
};

export const LossDateAndTimeSchema = {
  lossDate: yup
    .date()
    .typeError('Must be a valid date')
    .max(endOfDay(new Date()), "The Date of Loss can not be a later date than today's date")
    .required('Must be a valid date')
    .nullable(),
  lossTime: yup.date().when('lossDate', {
    is: (value: string | number | Date) => isToday(new Date(value)),
    then: yup
      .date()
      .typeError('Enter a valid time')
      .futureDate('Incident time cannot be later than current time')
      .required('Incident time is required')
      .nullable(),
    otherwise: yup
      .date()
      .typeError('Enter a valid time')
      .required('Incident time is required')
      .nullable(),
  }),
};
