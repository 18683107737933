import {Box, Divider, Grid, Hidden, IconButton, makeStyles} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';
import {ALL_PHONE_TYPES, PhoneTypes} from '../../../commonTypes';
import {useVerifyPhone} from '../../../hooks';
import {DynamicContainer} from '../DynamicContainer/DynamicContainer';
import {PhoneInput} from '../PhoneInput/PhoneInput';
import TrashCan from '../../../assets/images/TrashCan.svg';
import DisabledTrashCan from '../../../assets/images/DisabledTrashCan.svg';
import {useFeatureFlags} from '../Providers/Providers';

export interface MappedPhoneInfoByIdentifierProps {
  [id: string]: {
    number: string;
    type: string;
    extension: string;
    verifiedMobile: boolean;
  };
}

export interface MultiplePhoneNumbersProps {
  phoneTypeProps?: any;
  phoneExtensionProps?: any;
  id: string;
  errorProperty: any;
  scope: string;
  mandatoryFields?: number[];
  defaultMappedPhoneValues?: MappedPhoneInfoByIdentifierProps;
  defaultPhoneTypes?: PhoneTypes[];
}

const useStyles = makeStyles(theme => ({
  responsiveField: {
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  iconButton: {
    marginTop: '-30px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '0px',
    },
  },
}));

export const MultiplePhoneNumbers = ({
  phoneTypeProps,
  phoneExtensionProps,
  id,
  errorProperty,
  scope,
  mandatoryFields,
  defaultMappedPhoneValues,
  defaultPhoneTypes,
}: MultiplePhoneNumbersProps) => {
  const {featureFlags} = useFeatureFlags();
  const {shouldVerifyPhone, verifyMobileNumber} = useVerifyPhone();
  const classes = useStyles();
  const {register, control} = useFormContext();
  const name = `${scope}.phoneNumbers`;
  const {fields, append, remove} = useFieldArray({control, name});
  const [availablePhoneTypes, setAvailablePhoneTypes] = useState<PhoneTypes[]>(
    defaultPhoneTypes || ALL_PHONE_TYPES
  );
  const [mappedPhoneInfoValues, setMappedPhoneInfoValues] =
    useState<MappedPhoneInfoByIdentifierProps>({
      ...(defaultMappedPhoneValues || {
        0: {number: '', type: '', extension: '', verifiedMobile: false},
      }),
    });

  useEffect(() => {
    Object.keys(mappedPhoneInfoValues).forEach((key: string) => {
      append(
        {
          phoneNumber: mappedPhoneInfoValues?.[key]?.number || '',
          phoneType: mappedPhoneInfoValues?.[key]?.number || '',
          phoneExtension: mappedPhoneInfoValues?.[key]?.number || '',
          verifiedNumber: mappedPhoneInfoValues?.[key]?.verifiedMobile ? 'true' : 'false',
        },
        false
      );
    });

    const phoneToVerifyKey = Object.keys(mappedPhoneInfoValues).find(
      (key: string) =>
        shouldVerifyPhone(mappedPhoneInfoValues[key].number, mappedPhoneInfoValues[key].type) &&
        !mappedPhoneInfoValues[key].verifiedMobile
    );

    if (phoneToVerifyKey) {
      verifyMobileNumber(mappedPhoneInfoValues[phoneToVerifyKey].number).then(
        (verified: boolean) => {
          setMappedPhoneInfoValues({
            ...mappedPhoneInfoValues,
            [phoneToVerifyKey]: {
              ...mappedPhoneInfoValues[phoneToVerifyKey],
              verifiedMobile: verified,
            },
          });
        }
      );
    }
  }, []);

  const getNewAvailablePhoneTypes = (
    value: string,
    previousValue: string,
    currentAvailablePhoneTypes: PhoneTypes[]
  ) => {
    if (value && previousValue) {
      return [
        ...currentAvailablePhoneTypes.filter(type => (type as string) !== value),
        previousValue,
      ];
    } else if (value === '' && previousValue) {
      return [...currentAvailablePhoneTypes, previousValue];
    } else if (value && (previousValue === '' || previousValue === undefined)) {
      return [...currentAvailablePhoneTypes.filter(type => (type as string) !== value)];
    } else if (
      (value === '' || value === undefined) &&
      (previousValue === '' || previousValue === undefined)
    ) {
      return [...currentAvailablePhoneTypes];
    }
  };

  const handlePhoneTypeChange = (e: any, index: number) => {
    const value = e.target.value;
    const currentAvailablePhoneTypes = availablePhoneTypes;
    const phoneNumber = mappedPhoneInfoValues[index]?.number || '';
    const previousType = mappedPhoneInfoValues[index]?.type || '';
    const newAvailableTypes = getNewAvailablePhoneTypes(
      value,
      previousType,
      currentAvailablePhoneTypes
    ) as PhoneTypes[];

    setAvailablePhoneTypes(newAvailableTypes);

    if (shouldVerifyPhone(phoneNumber, value)) {
      verifyMobileNumber(phoneNumber).then(verified => {
        setMappedPhoneInfoValues(prevState => {
          let newState: MappedPhoneInfoByIdentifierProps = {};

          if (prevState[index]) {
            newState = {
              ...prevState,
              [index]: {...prevState[index], type: value, verifiedMobile: verified},
            };
          } else {
            newState = {
              ...prevState,
              [index]: {number: '', type: value, verifiedMobile: false},
            };
          }
          return newState;
        });
      });
    } else {
      setMappedPhoneInfoValues(prevState => {
        let newState: MappedPhoneInfoByIdentifierProps = {};

        if (prevState[index]) {
          newState = {
            ...prevState,
            [index]: {...prevState[index], type: value, verifiedMobile: false},
          };
        } else {
          newState = {
            ...prevState,
            [index]: {number: '', type: value, verifiedMobile: false},
          };
        }
        return newState;
      });
    }
  };

  const handlePhoneChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const value = e.target.value;
    const type = mappedPhoneInfoValues?.[index]?.type || '';

    if (
      shouldVerifyPhone(value, type) &&
      value !== (mappedPhoneInfoValues?.[index]?.number || '')
    ) {
      verifyMobileNumber(value).then(verified => {
        setMappedPhoneInfoValues(prevState => {
          let newState: MappedPhoneInfoByIdentifierProps = {};

          if (prevState[index]) {
            newState = {
              ...prevState,
              [index]: {...prevState[index], number: value, verifiedMobile: verified},
            };
          } else {
            newState = {
              ...prevState,
              [index]: {type: '', number: value, verifiedMobile: false},
            };
          }
          return newState;
        });
      });
    } else {
      setMappedPhoneInfoValues(prevState => {
        let newState: MappedPhoneInfoByIdentifierProps = {};

        if (prevState[index]) {
          newState = {
            ...prevState,
            [index]: {...prevState[index], number: value, verifiedMobile: false},
          };
        } else {
          newState = {
            ...prevState,
            [index]: {type: '', number: value, verifiedMobile: false},
          };
        }
        return newState;
      });
    }
  };

  const handlePhoneExtensionChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const value = e.target.value;
    const phoneNumber = mappedPhoneInfoValues[index]?.number || '';
    const type = mappedPhoneInfoValues?.[index]?.type || '';

    if (
      shouldVerifyPhone(phoneNumber, value) &&
      value !== (mappedPhoneInfoValues?.[index]?.extension || '')
    ) {
      verifyMobileNumber(phoneNumber).then(verified => {
        setMappedPhoneInfoValues(prevState => {
          let newState: MappedPhoneInfoByIdentifierProps = {};

          if (prevState[index]) {
            newState = {
              ...prevState,
              [index]: {...prevState[index], extension: value, verifiedMobile: verified},
            };
          } else {
            newState = {
              ...prevState,
              [index]: {number: '', extension: value, verifiedMobile: false},
            };
          }
          return newState;
        });
      });
    } else {
      setMappedPhoneInfoValues(prevState => {
        let newState: MappedPhoneInfoByIdentifierProps = {};

        if (prevState[index]) {
          newState = {
            ...prevState,
            [index]: {...prevState[index], extension: value, verifiedMobile: false},
          };
        } else {
          newState = {
            ...prevState,
            [index]: {number: '', extension: value, verifiedMobile: false},
          };
        }
        return newState;
      });
    }
  };

  const removePhone = (index: number, remove: (index?: number | number[] | undefined) => void) => {
    remove(index);
    delete mappedPhoneInfoValues[index];
    setMappedPhoneInfoValues(
      Object.fromEntries(
        Object.entries(mappedPhoneInfoValues)
          .filter(el => parseInt(el[0]) !== index)
          .map((el, i) => [i.toString(), el[1]])
      )
    );
    let newPhoneTypes: string[] = [];
    Object.entries(mappedPhoneInfoValues).forEach(([, value]) => newPhoneTypes.push(value.type));
    setAvailablePhoneTypes(ALL_PHONE_TYPES.filter(type => newPhoneTypes.indexOf(type) < 0));
  };

  return (
    <Grid container>
      {fields?.map((field, index) => (
        <Grid item xs={12} key={field.id}>
          <DynamicContainer
            addButtonText="ADD PHONE NUMBER"
            addController={() =>
              append(
                {phoneNumber: '', phoneType: '', phoneExtension: '', verifiedNumber: 'false'},
                false
              )
            }
            displayAddButton={index === fields.length - 1 && fields.length < 3}
            displayFieldDivider={
              index + 1 !== fields.length ? (
                <Hidden smUp>
                  <Box marginLeft="2px" marginRight="2px" marginTop="0px" marginBottom="20px">
                    <Divider />
                  </Box>
                </Hidden>
              ) : undefined
            }
          >
            <Box pb={index === fields.length - 1 ? 3 : 2}>
              <Grid container spacing={1} justify="flex-start" className={classes.responsiveField}>
                <Grid item xs={10}>
                  {featureFlags?.FF_DCX_2385 && (
                    <input
                      name={`${name}[${index}].verifiedNumber`}
                      ref={register()}
                      type="hidden"
                      value={mappedPhoneInfoValues?.[index]?.verifiedMobile ? 'true' : 'false'}
                      data-testid={`${scope}-verifiedNumberInput-${index + 1}`}
                    />
                  )}
                  <PhoneInput
                    id={`${id}-${index}`}
                    name={`${name}[${index}].phoneNumber`}
                    InputLabelProps={{'aria-labelledby': `${id}-${index}`}}
                    label={index !== 0 ? `Phone Number ${index + 1}` : `Phone Number`}
                    inputRef={register()}
                    data-testid={`${id}-${index}`}
                    onChange={(event: any) => handlePhoneChange(event, index)}
                    hasError={errorProperty?.[index]?.hasOwnProperty(`phoneNumber`)}
                    helperText={
                      errorProperty?.[index]?.phoneNumber?.message ||
                      (mandatoryFields && !mandatoryFields.includes(index) && 'optional')
                    }
                    value={
                      mappedPhoneInfoValues?.[index]
                        ? mappedPhoneInfoValues?.[index]?.number
                        : undefined
                    }
                    phoneTypeProps={{
                      showPhoneType:
                        phoneTypeProps?.showPhoneType !== undefined
                          ? phoneTypeProps.showPhoneType
                          : true,
                      name: `${name}[${index}].phoneType`,
                      value: mappedPhoneInfoValues?.[index]
                        ? mappedPhoneInfoValues[index].type
                        : '',
                      phoneTypes:
                        mappedPhoneInfoValues && availablePhoneTypes
                          ? mappedPhoneInfoValues[index] && mappedPhoneInfoValues?.[index].type
                            ? [
                                ...availablePhoneTypes,
                                mappedPhoneInfoValues[index].type as PhoneTypes,
                              ]
                            : availablePhoneTypes
                          : phoneTypeProps?.phoneTypes,
                      onChange: (event: any) => {
                        handlePhoneTypeChange(event, index);
                      },
                      hasError:
                        errorProperty && errorProperty?.[index]?.hasOwnProperty(`phoneType`),
                      helperText: errorProperty && errorProperty?.[index]?.phoneType?.message,
                      testId: `${phoneTypeProps?.testId}-${index}`,
                      id: `${scope}-phoneType-${index + 1}`,
                      inputRef: register(),
                    }}
                    phoneExtensionProps={{
                      showPhoneExtension:
                        phoneExtensionProps?.showPhoneExtension !== undefined
                          ? phoneExtensionProps.showPhoneExtension
                          : true,
                      name: `${name}[${index}].phoneExtension`,
                      value: mappedPhoneInfoValues?.[index]
                        ? mappedPhoneInfoValues[index].extension
                        : '',
                      onChange: (event: any) => {
                        handlePhoneExtensionChange(event, index);
                      },
                      hasError:
                        errorProperty && errorProperty?.[index]?.hasOwnProperty(`phoneExtension`),
                      helperText: errorProperty && errorProperty?.[index]?.phoneExtension?.message,
                      label: index !== 0 ? `Phone Extension ${index + 1}` : `Phone Extension`,
                      testId: `${phoneExtensionProps?.testId}-${index}`,
                      id: `${scope}-phoneExtension-${index + 1}`,
                      inputRef: register(),
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    disabled={fields.length === 1}
                    onClick={() => removePhone(index, remove)}
                    data-testid={`${scope}-remove-phone-${index}`}
                    className={classes.iconButton}
                  >
                    <img alt="Remove" src={fields.length === 1 ? DisabledTrashCan : TrashCan} />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          </DynamicContainer>
        </Grid>
      ))}
    </Grid>
  );
};
