import React from 'react';
import {Typography} from '@material-ui/core';
import {FormContainer} from '../../common/FormContainer/FormContainer';
import {useFormContext, useWatch} from 'react-hook-form';
import {
  stateValidation,
  phoneValidation,
  emailValidation,
  zipCodeValidation,
  cityValidation,
  streetAddressValidation,
  firstNameValidation,
  lastNameValidation,
} from '../../../validations';
import * as yup from 'yup';
import {
  RegisterBreadcrumb,
  WaypointNames,
  WAYPOINTS,
} from '../../common/RegisterBreadcrumb/RegisterBreadcrumb';
import {makePersonaCheck, personaSchemaSwitch, hasContactRoles} from '../../../utils/utils';
import {ContactRoles, CustomerTypes, CustomerTypesObj, Lobs} from '../../../commonTypes';
import {UserPersonaSwitch} from '../../common/UserPersonaSwitch/UserPersonaSwitch';
import {
  DEFAULT_REPORTER_ATOM_VALUE,
  ReporterData,
  useManageContactOccurrences,
  useSetReporterAtomState,
  useUserAtomState,
} from '../../../atoms';
import {ContactInfo} from '../../common/ContactInfo/ContactInfo';
import {ContactSections, FnolFormContact} from '../../common/ContactInfo/types';
import {SABox, SAColumns} from '@saux/design-system-react';
import {SASelect} from '../../common/Select/Select';
import {NoticeOnly} from '../../common/NoticeOnly/NoticeOnly';
import {phoneExtensionValidationRegex} from '../../common/ContactInfo/ContactInfoModal/ContactInfoModal';

export const GettingStartedSchema = {
  gettingStarted: yup.object().shape({
    firstName: firstNameValidation().required('First Name is required'),
    lastName: lastNameValidation().required('Last Name is required'),
    address: streetAddressValidation().nullable(),
    city: cityValidation(true).nullable(),
    state: stateValidation().nullable(),
    zipcode: zipCodeValidation(true).nullable(),
    phoneNumbers: yup.array().of(
      yup.object().shape({
        phoneNumber: yup.lazy((item?: any, options?: any) => {
          if (options.path === 'gettingStarted.phoneNumbers[0].phoneNumber') {
            return phoneValidation(true).required('Phone Number is required');
          } else {
            return phoneValidation(true).nullable();
          }
        }),
        phoneType: yup.lazy((item?: any, options?: any) => {
          if (options.parent.phoneNumber !== '') {
            return yup.string().required('Phone Type is required');
          } else {
            return yup.string().nullable();
          }
        }),
        phoneExtension: yup.string().when('phoneNumber', {
          is: (value: string) => !!value,
          then: yup
            .string()
            .matches(
              phoneExtensionValidationRegex,
              'Phone extension must be a numeric value of up to 4 digits.'
            )
            .nullable(),
          otherwise: yup
            .string()
            .test(
              'not-allowed',
              'Phone extension should be empty if no phone number provided.',
              function (value) {
                return !value;
              }
            )
            .nullable(),
        }),
      })
    ),
    email: personaSchemaSwitch({
      is: (value: CustomerTypes) =>
        makePersonaCheck(value, [
          CustomerTypes.SaAgent,
          CustomerTypes.SaCustomer,
          CustomerTypes.ThirdParty,
        ]),
      then: emailValidation(true).required('Email address is required').nullable(),
      otherwise: emailValidation(true).nullable(),
    }),
    howReported: personaSchemaSwitch({
      is: (value: CustomerTypes) => makePersonaCheck(value, [CustomerTypes.Associate]),
      then: yup.string().required('Please select how the claim is being reported'),
    }),
    reporterId: yup.string().required('Please select who is reporting the claim'),
  }),
};

export const GettingStartedTestSchema = yup.object().shape({
  ...GettingStartedSchema,
});

export enum HowReported {
  Phone = 'phone_ext',
  Email = 'email_ext',
  Mail = 'mail_ext',
  Fax = 'fax_ext',
  Internet = 'internet_ext',
  DirectVendor = 'Vendor',
}

export const HOW_REPORTED: CustomerTypesObj = {
  [HowReported.Phone]: 'Phone',
  [HowReported.Email]: 'Email',
  [HowReported.Mail]: 'Mail',
  [HowReported.Fax]: 'Fax',
  [HowReported.Internet]: 'State Auto Website',
  [HowReported.DirectVendor]: 'Direct to Vendor',
};

interface GettingStartedProps {
  setShowInsuredContact: (display: boolean) => void;
}

export const GettingStarted = ({setShowInsuredContact}: GettingStartedProps) => {
  const {register, errors, control} = useFormContext();
  const [userAtomState] = useUserAtomState();
  const userPersona = userAtomState?.gettingStarted?.customerType;
  const lob = userAtomState?.gettingStarted?.lob;
  const isAssociate = userPersona === CustomerTypes.Associate;
  const setReporterAtom = useSetReporterAtomState();
  const insuredContactId = useWatch({
    name: 'insuredContactInformation.contactID',
    defaultValue: '',
    control,
  });
  const {removeContactOccurrences} = useManageContactOccurrences();

  const getSection = () => {
    switch (lob) {
      case Lobs.Auto:
        return ContactSections.ReporterAuto;
      case Lobs.Homeowners:
        return ContactSections.ReporterHome;
      default:
        return ContactSections.ReporterAuto;
    }
  };

  const getSubheading = () => {
    if (userPersona === CustomerTypes.SaAgent) {
      return 'Agent Information';
    }

    if (userPersona === CustomerTypes.Associate) {
      return 'Who is reporting the claim?';
    }

    return 'Please tell us about yourself.';
  };

  const onReporterChange = (formContact: FnolFormContact) => {
    if (formContact.fnolId) {
      setReporterAtom((state: ReporterData) =>
        formContact.policyInfo
          ? {
              ...state,
              contact: {...formContact.policyInfo, publicID: formContact.fnolId},
              isPolicyContact: true,
            }
          : {
              ...state,
              contact: {
                firstName: formContact.firstName,
                lastName: formContact.lastName,
                suffix: formContact.suffix,
                publicID: formContact.fnolId,
              },
              isPolicyContact: false,
            }
      );

      if (isAssociate) {
        const showInsuredContact =
          !formContact.policyInfo ||
          (formContact.policyInfo &&
            !hasContactRoles(formContact.policyInfo, [ContactRoles.Insured]));

        setShowInsuredContact(showInsuredContact);

        if (!showInsuredContact) {
          removeContactOccurrences([
            {
              id: insuredContactId,
              sections: [ContactSections.InsuredMainContact],
            },
          ]);
        }
      }
    } else {
      setReporterAtom((state: ReporterData) => {
        return {...DEFAULT_REPORTER_ATOM_VALUE, howReported: state.howReported};
      });

      if (isAssociate) {
        setShowInsuredContact(false);
        removeContactOccurrences([
          {
            id: insuredContactId,
            sections: [ContactSections.InsuredMainContact],
          },
        ]);
      }
    }
  };

  const handleHowReportedChange = (event: any) => {
    const howReported = event.target.value;

    setReporterAtom((state: ReporterData) => {
      return {...state, howReported};
    });
  };

  return (
    <RegisterBreadcrumb
      waypointName={WaypointNames.GettingStarted}
      displayName={WAYPOINTS[WaypointNames.GettingStarted].displayName}
    >
      <FormContainer header={isAssociate ? 'Reporter Information' : 'Getting Started'}>
        <>
          <UserPersonaSwitch
            ifPersonas={[CustomerTypes.Associate]}
            then={
              <>
                <SABox pb="medium">
                  <Typography variant="body1">How is the claim being reported?</Typography>
                </SABox>
                <SAColumns columns={{xs: [12], sm: [6], md: [4]}}>
                  <SABox pb="large">
                    <SASelect
                      label="How reported?"
                      id="howReported"
                      inputProps={{'aria-label': 'How reported?'}}
                      data-testid="howReported"
                      name="gettingStarted.howReported"
                      error={errors?.gettingStarted?.hasOwnProperty('howReported')}
                      helperText={errors?.gettingStarted?.howReported?.message}
                      inputRef={register()}
                      onChange={(event: any) => handleHowReportedChange(event)}
                    >
                      {Object.values(HowReported).map(item => (
                        <option value={item} key={item}>
                          {HOW_REPORTED[item]}
                        </option>
                      ))}
                    </SASelect>
                  </SABox>
                </SAColumns>
              </>
            }
          />
          <SABox pb="medium">
            <Typography variant="body1">{getSubheading()}</Typography>
          </SABox>
          <SABox>
            <ContactInfo
              section={getSection()}
              parentFieldName="gettingStarted"
              selectProps={{
                name: 'reporterId',
                label: 'Reporter',
                id: 'reporterId',
                inputProps: {'aria-label': 'Reporter'},
              }}
              errorsObj={errors?.gettingStarted}
              onContactChange={onReporterChange}
              hiddenFields={{
                email: {render: true},
                phoneNumbers: {render: true},
                address: {render: true},
                city: {render: true},
                state: {render: true},
                zipCode: {render: true, name: 'zipcode'},
              }}
            />
          </SABox>
          <UserPersonaSwitch
            ifPersonas={[CustomerTypes.Associate]}
            then={
              <SABox pb="medium" pt="large">
                <NoticeOnly />
              </SABox>
            }
          />
        </>
      </FormContainer>
    </RegisterBreadcrumb>
  );
};
