import {ClaimLobs, ContactRoles} from '../commonTypes';
import {VehicleLocationTypes} from '../components/auto/VehicleDriveable/VehicleDriveable';
import {suffixTypes} from '../components/common/ContactInfo/ContactInfoModal/ContactInfoModal';
import {Contact, LabelValue, VehicleIncident} from '../components/common/Summary/types';
import {capitalize, grabClaimLob} from './utils';

export const mapData = (ccData: any, formData: any, draftClaim: any) => {
  const lob: string = grabClaimLob(draftClaim?.result?.lobs);
  const {cellNumber, workNumber, homeNumber} = mapPhoneNumberDetails(
    formData?.gettingStarted?.phoneNumbers
  );

  let mappedData: any = {
    ...(ccData || {}),
    lobs: {
      ...(ccData?.lobs || {[lob]: {}}),
    },
    mainContact: {
      emailAddress1: formData?.gettingStarted?.email,
      cellNumber: cellNumber,
      workNumber: workNumber,
      homeNumber: homeNumber,
      displayName: `${formData?.gettingStarted?.firstName} ${formData?.gettingStarted?.lastName} ${
        formData.gettingStarted.suffix ? '' + suffixTypes[formData.gettingStarted.suffix] : ''
      }`.trim(),
      primaryAddress: {
        addressLine1: formData?.gettingStarted?.address,
        city: formData?.gettingStarted?.city,
        state: formData?.gettingStarted?.state,
        postalCode: formData?.gettingStarted?.zipcode,
      },
    },
    // incident details
    lossCause:
      formData?.incidentDetails.natureOfLoss || formData?.incidentDetails?.firstIncident.value,
    incidentDescription: formData?.incidentDetails.otherDetails,
    description: formData?.incidentDetails.otherDetails,
    selectedIncidents: formData?.incidentDetails?.incidents
      ? mapIncidents(formData?.incidentDetails?.incidents)
      : '',
    rideshareOrDelivery: formData?.incidentDetails?.rideshareOrDelivery || '',
    rideshare: formData?.incidentDetails?.rideshare || '',
    delivery: formData?.incidentDetails?.delivery || '',
    isInsuredBusiness: formData?.incidentDetails?.isInsuredBusiness || '',
    deliveryBusinessName: formData?.incidentDetails?.deliveryBusinessName || '',
    formData: {
      ...(formData || {}),
      generalDetails: {
        ...(formData?.generalDetails || {}),
        lossDate: formData?.lossDate,
      },
    },
  };

  mappedData = mapOtherDetailsSection(mappedData, formData);
  mappedData = mapAnyoneElseInjured(mappedData, formData, ccData, lob);
  mappedData = mapAutoLobs(mappedData, formData, ccData, lob);
  mappedData = mapPropertyDamageDetails(mappedData, formData, draftClaim, lob);
  mappedData = mapAdditionalNotes(mappedData, formData, ccData);
  return mappedData;
};

export const mapAutoLobs = (mapData: any, formData: any, ccData: any, lob: string) => {
  let mappedData: any = mapData;

  if (formData.additionalVehicles) {
    mappedData.lobs[lob] = {
      ...(ccData?.lobs?.[lob] || {}),
    };
    mappedData.lobs[lob].vehicleIncidents = mapVehicleIncidents(formData, ccData, lob);
  }

  return mappedData;
};

export const mapVehicleIncidents = (formData: any, ccData: any, lob: string) => {
  let mappedData: any = [];
  formData.additionalVehicles.forEach((vehicle: any) => {
    let vehicleIncident: VehicleIncident = {};

    const claimCenterVehicleInfo =
      ccData?.lobs?.[lob]?.vehicleIncidents.find((v: VehicleIncident) => {
        const vehicleIncidentVin = v.vehicle?.vIN ? v.vehicle?.vIN : '';
        const vehicleVIN = vehicle.vehicleVIN ? vehicle.vehicleVIN : '';
        if (
          `${v.vehicle?.year}` === `${vehicle.vehicleYear}` &&
          v.vehicle?.make === vehicle.vehicleMake &&
          v.vehicle?.model === vehicle.vehicleModel &&
          vehicleIncidentVin === vehicleVIN
        ) {
          return v;
        }
      }) || {};
    vehicleIncident.publicID = claimCenterVehicleInfo?.publicID || '';

    vehicleIncident.vehicle = {
      publicID: vehicle.vehicleId || claimCenterVehicleInfo?.vehicle?.publicID || '',
      make: vehicle.vehicleMake || '',
      model: vehicle.vehicleModel || '',
      vIN: vehicle.vehicleVIN || '',
      year: vehicle.vehicleYear || '',
      style: vehicle.vehicleType || '',
    };

    vehicleIncident.impactPoints = vehicle.damages || [];
    vehicleIncident.vehicleDriveable = mapYesNoUnkReturnValue(vehicle.vehicleDriveable);
    vehicleIncident.primaryImpactPoints = vehicle.firstImpactPoint || '';
    vehicleIncident.isYours = vehicle.isYours || '';
    vehicleIncident.airbagsDeployed = mapYesNoUnkReturnValue(vehicle.airbagsDeployed);

    if (vehicle.vehicleDriveableAdditionalInfo) {
      vehicleIncident.vehicleInCustomerPossession =
        vehicle.vehicleDriveableAdditionalInfo.vehicleInCustomerPossession;
      vehicleIncident.vehicleLocationSelection =
        vehicle.vehicleDriveableAdditionalInfo.vehicleLocationSelection;
      vehicleIncident.phoneNumber = vehicle.vehicleDriveableAdditionalInfo.phoneNumber;
      vehicleIncident.phoneExtension = vehicle.vehicleDriveableAdditionalInfo.phoneExtension;
      if (vehicleIncident.vehicleLocationSelection === VehicleLocationTypes.OtherLocation) {
        vehicleIncident.firstName = vehicle.vehicleDriveableAdditionalInfo.firstName;
        vehicleIncident.lastName = vehicle.vehicleDriveableAdditionalInfo.lastName;
        vehicleIncident.suffix = vehicle.vehicleDriveableAdditionalInfo.suffix
          ? suffixTypes[vehicle.vehicleDriveableAdditionalInfo.suffix]
          : ''.trim();
      } else {
        vehicleIncident.companyName = vehicle.vehicleDriveableAdditionalInfo.companyName;
      }
    }

    if (vehicle.vehicleDriveable === 'no') {
      vehicleIncident.locationAddress = {
        addressLine1: vehicle.address || '',
        city: vehicle.city || '',
        state: vehicle.state || '',
        postalCode: vehicle.zipCode || '',
      };
    }

    if (vehicle.vehicleHasEngineWaterDamage === 'yes') {
      vehicleIncident?.impactPoints?.push({
        label: 'vehicleHasEngineWaterDamage',
        value: true,
      });
    } else {
      vehicleIncident?.impactPoints?.push({
        label: 'vehicleHasEngineWaterDamage',
        value: false,
      });
    }

    if (vehicle.vehicleRolledOver === 'yes') {
      vehicleIncident?.impactPoints?.push({
        label: 'vehicleRolledOver',
        value: true,
      });
    } else {
      vehicleIncident?.impactPoints?.push({
        label: 'vehicleRolledOver',
        value: false,
      });
    }

    if (
      formData.incidentDetails.isHailDamage === 'yes' &&
      formData.incidentDetails.firstIncident === 'Other Weather'
    ) {
      vehicleIncident?.impactPoints?.push({label: 'hail', value: true});
    } else {
      vehicleIncident?.impactPoints?.push({label: 'hail', value: false});
    }

    vehicleIncident = mapOwnerAndDriverData(vehicleIncident, vehicle, formData);
    vehicleIncident = mapPassengers(vehicleIncident, vehicle);
    vehicleIncident = mapIntegrationEligibility(vehicleIncident, ccData, lob);
    mappedData.push(vehicleIncident);
  });

  return mappedData;
};

export const mapPassengers = (vehicleIncident: any, vehicle: any) => {
  let passengersArray: any = [];
  if (vehicle.additionalPassenger) {
    vehicle.additionalPassenger.forEach((formPassenger: any) => {
      let passenger: Contact = {};
      const {cellNumber, workNumber, homeNumber} = mapPhoneNumberDetails(
        formPassenger?.phoneNumbers
      );
      passenger.firstName = formPassenger.firstName || '';
      passenger.lastName = formPassenger.lastName || '';
      passenger.suffix = formPassenger.suffix || '';
      if (cellNumber) passenger.cellNumber = cellNumber;
      if (workNumber) passenger.workNumber = workNumber;
      if (homeNumber) passenger.homeNumber = homeNumber;
      passenger.emailAddress1 = formPassenger.email || '';
      passenger.injured = mapYesNoUnkReturnValue(formPassenger.injured);
      passenger.subtype = 'Person';
      passenger.displayName =
        passenger.firstName && passenger.lastName
          ? `${formPassenger.firstName} ${formPassenger.lastName} ${
              formPassenger.suffix ? '' + suffixTypes[formPassenger.suffix] : ''
            }`.trim()
          : '';
      passenger.primaryAddress = {
        addressLine1: formPassenger.address || '',
        city: formPassenger.city || '',
        state: formPassenger.state || '',
        postalCode: formPassenger.zipCode || '',
      };
      passengersArray.push(passenger);
    });
  }

  vehicleIncident.passengers = passengersArray;
  return vehicleIncident;
};

export const mapOwnerAndDriverData = (vehicleIncident: any, mv: any, formData: any) => {
  let ownerData: any = {};
  let driverData: any = {};

  // Owner details
  if (mv?.notOwner?.firstName || mv?.notOwner?.companyName) {
    const {cellNumber, workNumber, homeNumber} = mapPhoneNumberDetails(mv.notOwner?.phoneNumbers);
    ownerData.publicID = mv.notOwner.ownerId || '';
    ownerData.firstName = mv.notOwner.firstName || '';
    ownerData.lastName = mv.notOwner.lastName || '';
    ownerData.primaryPhoneType = mv.notOwner.phoneType || '';
    ownerData.primaryPhoneExtension = mv.notOwner.phoneExtension || '';
    if (cellNumber) ownerData.cellNumber = cellNumber;
    if (workNumber) ownerData.workNumber = workNumber;
    if (homeNumber) ownerData.homeNumber = homeNumber;
    ownerData.emailAddress1 = mv.notOwner.email || '';
    ownerData.insuranceProvider = mv.notOwner.insuranceProvider || '';
    ownerData.policyOrClaimNumber = mv.notOwner.policyOrClaimNumber || '';
    ownerData.subType = mv.notOwner.radioSelection || '';
    ownerData.primaryAddress = {
      addressLine1: mv.notOwner.address || '',
      city: mv.notOwner.city || '',
      state: mv.notOwner.state || '',
      postalCode: mv.notOwner.zipcode || '',
    };

    if (mv?.notOwner?.firstName) {
      ownerData.displayName =
        ownerData.firstName && ownerData.lastName
          ? `${ownerData.firstName} ${ownerData.lastName} ${
              mv.notOwner.suffix ? '' + suffixTypes[mv.notOwner.suffix] : ''
            }`.trim()
          : '';
    } else if (mv?.notOwner?.companyName) {
      ownerData.displayName = mv?.notOwner?.companyName || '';
    }
  } else {
    const {cellNumber, workNumber, homeNumber} = mapPhoneNumberDetails(
      formData.gettingStarted?.phoneNumbers
    );
    ownerData.firstName = formData.gettingStarted.firstName || '';
    ownerData.lastName = formData.gettingStarted.lastName || '';
    if (cellNumber) ownerData.cellNumber = cellNumber;
    if (workNumber) ownerData.workNumber = workNumber;
    if (homeNumber) ownerData.homeNumber = homeNumber;
    ownerData.emailAddress1 = formData.gettingStarted.email || '';
    ownerData.displayName =
      ownerData.firstName && ownerData.lastName
        ? `${ownerData.firstName} ${ownerData.lastName} ${
            ownerData.suffix ? '' + suffixTypes[ownerData.suffix] : ''
          }`.trim()
        : '';
    ownerData.primaryAddress = {
      addressLine1: formData.gettingStarted.address || '',
      city: formData.gettingStarted.city || '',
      state: formData.gettingStarted.state || '',
      postalCode: formData.gettingStarted.zipcode || '',
    };
  }
  vehicleIncident.owner = ownerData;

  // Driver Details
  if (
    mv?.notDriver?.driverSameAsOwner === 'no' ||
    (mv?.notDriver?.firstName && mv?.notDriver?.lastName)
  ) {
    const {cellNumber, workNumber, homeNumber} = mapPhoneNumberDetails(mv.notDriver?.phoneNumbers);
    driverData.publicID = mv.notDriver.driverId || '';
    driverData.firstName = mv.notDriver.firstName || '';
    driverData.lastName = mv.notDriver.lastName || '';
    if (cellNumber) driverData.cellNumber = cellNumber;
    if (workNumber) driverData.workNumber = workNumber;
    if (homeNumber) driverData.homeNumber = homeNumber;
    driverData.insuranceProvider = mv.notDriver.insuranceProvider || '';
    driverData.policyOrClaimNumber = mv.notDriver.policyOrClaimNumber || '';
    driverData.displayName =
      driverData.firstName && driverData.lastName
        ? `${driverData.firstName} ${driverData.lastName} ${
            mv.notDriver.suffix ? '' + suffixTypes[mv.notDriver.suffix] : ''
          }`.trim()
        : '';
    driverData.primaryAddress = {
      addressLine1: mv.notDriver.address,
      city: mv.notDriver.city,
      state: mv.notDriver.state,
      postalCode: mv.notDriver.zipcode,
    };
  } else if (mv?.notDriver?.driverSameAsOwner === 'yes') {
    driverData.publicID = ownerData.publicID || '';
    driverData.firstName = ownerData.firstName || '';
    driverData.lastName = ownerData.lastName || '';
    if (ownerData.cellNumber) driverData.cellNumber = ownerData.cellNumber;
    if (ownerData.workNumber) driverData.workNumber = ownerData.workNumber;
    if (ownerData.homeNumber) driverData.homeNumber = ownerData.homeNumber;
    driverData.insuranceProvider = ownerData.insuranceProvider || '';
    driverData.policyOrClaimNumber = ownerData.policyOrClaimNumber || '';
    driverData.displayName =
      driverData.firstName && driverData.lastName
        ? `${driverData.firstName} ${driverData.lastName} ${
            ownerData.suffix ? '' + suffixTypes[ownerData.suffix] : ''
          }`.trim()
        : '';
    driverData.primaryAddress = {
      addressLine1: ownerData.primaryAddress.addressLine1 || '',
      city: ownerData.primaryAddress.city || '',
      state: ownerData.primaryAddress.state || '',
      postalCode: ownerData.primaryAddress.postalCode || '',
    };
  }
  if (
    mv?.notDriver?.driverSameAsOwner !== 'noDriver' &&
    (mv?.notDriver?.wereYouInjured === 'yes' || mv?.notDriver?.wereYouInjured === 'no')
  ) {
    driverData.injured = mapYesNoUnkReturnValue(
      mv.notDriver && mv.notDriver.wereYouInjured ? mv.notDriver.wereYouInjured : ''
    );
  }

  vehicleIncident.driver = driverData;
  return vehicleIncident;
};

export const mapIntegrationEligibility = (vehicleIncidentData: any, ccData: any, lob: string) => {
  let vehicleIncident: any = vehicleIncidentData;

  if (ccData?.lobs && ccData.lobs[lob]?.vehicleIncidents) {
    ccData.lobs[lob].vehicleIncidents.forEach((ccVehicleReturn: any) => {
      if (
        ccVehicleReturn.vehicle &&
        ccVehicleReturn.driver &&
        ccVehicleReturn.driver.displayName === vehicleIncident.driver?.displayName &&
        ccVehicleReturn.vehicle.year.toString() === vehicleIncident.vehicle?.year &&
        ccVehicleReturn.vehicle.make === vehicleIncident.vehicle?.make &&
        ccVehicleReturn.vehicle.model === vehicleIncident.vehicle?.model
      ) {
        vehicleIncident.quickEstimateEligible = ccVehicleReturn.quickEstimateEligible;
        vehicleIncident.staffAppraiserEligible = ccVehicleReturn.staffAppraiserEligible;
        vehicleIncident.copartAppraiserEligible = ccVehicleReturn.copartAppraiserEligible;
      }
    });
  }

  return vehicleIncident;
};

export const mapOtherDetailsSection = (mapData: any, formData: any) => {
  let mappedData: any = mapData;
  let witnesses: any = [];

  if (formData.additionalWitness) {
    formData.additionalWitness.forEach((witness: any) => {
      const {cellNumber, workNumber, homeNumber} = mapPhoneNumberDetails(witness?.phoneNumbers);
      witnesses.push({
        displayName: witness.firstName
          ? `${witness.firstName || ''} ${witness.lastName || ''} ${
              witness.suffix ? '' + suffixTypes[witness.suffix] : ''
            }`.trim()
          : '',
        firstName: witness.firstName || '',
        lastName: witness.lastName || '',
        suffix: witness.suffix || '',
        cellNumber,
        workNumber,
        homeNumber,
      });
    });
  }

  if (witnesses.length !== 0) {
    mappedData.witnesses = witnesses;
  }

  let officials: any = [];

  if (formData.otherDetails) {
    const officialType = (
      reportType: any,
      departmentName: any,
      reportNumber: any,
      officialType: any
    ) => {
      return {
        officialName: departmentName,
        reportNumber: reportNumber,
        officialType: officialType,
        officialReportType: reportType,
      };
    };

    if (formData.otherDetails.wasThereAFireReport === 'yes') {
      officials.push(
        officialType(
          formData.otherDetails.fireReportType || '',
          formData.otherDetails.fireDepartmentName || '',
          formData.otherDetails.fireReportNumber || '',
          'fire'
        )
      );
    }
    if (formData.otherDetails.wasThereAnAmbulanceReport === 'yes') {
      officials.push(
        officialType(
          '',
          formData.otherDetails.ambulanceDepartmentName || '',
          formData.otherDetails.ambulanceReportNumber || '',
          'ambulance'
        )
      );
    }
    if (formData.otherDetails.wasThereAPoliceReport === 'yes') {
      officials.push(
        officialType(
          formData.otherDetails.policeReportType || '',
          formData.otherDetails.policeDepartmentName || '',
          formData.otherDetails.policeReportNumber || '',
          'police'
        )
      );
    }
  }

  if (officials.length !== 0) {
    mappedData.officials = officials;
  }

  return mappedData;
};

export const mapAnyoneElseInjured = (mapData: any, formData: any, ccData: any, lob: any) => {
  let mappedData: any = mapData;
  let injuryIncidents: any = [];

  if (mappedData.lobs && mappedData.lobs[lob]) {
    if (formData.additionalInjured) {
      formData.additionalInjured.forEach((person: any) => {
        const {cellNumber, workNumber, homeNumber} = mapPhoneNumberDetails(person?.phoneNumbers);
        injuryIncidents.push({
          generalInjuryType: 'specific',
          injuryType: 'additionalInjury',
          injured: {
            firstName: person.firstName || '',
            lastName: person.lastName || '',
            suffix: person.suffix || '',
            displayName:
              person.firstName && person.lastName
                ? `${person.firstName} ${person.lastName} ${
                    person.suffix ? suffixTypes[person.suffix] : ''
                  }`.trim()
                : '',
            emailAddress1: person.emailAddress || '',
            cellNumber,
            workNumber,
            homeNumber,
            primaryAddress: {
              addressLine1: person.address || '',
              city: person.city || '',
              state: person.state || '',
              postalCode: person.zipCode || '',
            },
          },
        });
      });
    } else if (ccData?.lobs?.[lob]?.injuryIncidents) {
      injuryIncidents = ccData.lobs[lob].injuryIncidents;
    }

    mappedData.lobs[lob].injuryIncidents = injuryIncidents;
  }

  return mappedData;
};

export const mapPropertyDamageDetails = (
  mapData: any,
  formData: any,
  draftClaim: any,
  lob: string
) => {
  let mappedData: any = mapData;
  let propertyDamageDetailsArray: any = [];

  if (lob === ClaimLobs.PersonalAuto || lob === ClaimLobs.CommercialAuto) {
    if (formData.additionalProperty) {
      formData.additionalProperty.forEach((property: any) => {
        propertyDamageDetailsArray.push(mapPropertyValues(property));
      });
    }

    if (formData.yourProperty) {
      const yourPropertyPhone = formData.yourProperty[0].phoneNumbers;
      formData.yourProperty.forEach((property: any) => {
        propertyDamageDetailsArray.push(
          mapPropertyValues(property, yourPropertyPhone ? property : formData.gettingStarted)
        );
      });
    }
  } else {
    let damageDetails: any = {};

    if (draftClaim && draftClaim.result && draftClaim.result.contacts) {
      let contact = draftClaim.result.contacts.filter((contact: any) =>
        contact.contactRoles.includes(ContactRoles.Insured)
      );
      if (contact.length !== 0) {
        contact = contact[0];
        const ownerType = contact.contactType || 'Person';
        damageDetails.propertyOwner = {
          contactType: ownerType,
          emailAddress1: contact.emailAddress1 || '',
          displayName: contact.displayName || '',
        };

        if (contact.primaryAddress) {
          damageDetails.propertyAddress = {
            addressLine1: contact.primaryAddress.addressLine1 || '',
            city: contact.primaryAddress.city || '',
            state: contact.primaryAddress.state || '',
            postalCode: contact.primaryAddress.postalCode || '',
          };
          damageDetails.propertyOwner.primaryAddress = damageDetails.propertyAddress;
        }
      }
    }

    propertyDamageDetailsArray.push(damageDetails);
  }

  if (formData.otherProperty) {
    formData.otherProperty.forEach((property: any) => {
      propertyDamageDetailsArray.push(mapPropertyValues(property));
    });
  }

  if (mappedData.lobs[lob].fixedPropertyIncidents) {
    mappedData.lobs[lob].fixedPropertyIncidents = propertyDamageDetailsArray;
  } else {
    mappedData.lobs[lob].propertyIncidents = propertyDamageDetailsArray;
  }

  return mappedData;
};

export const mapPropertyValues = (propertyData: any, contactInfo?: any) => {
  const contactData = contactInfo || propertyData;
  const ownerType = propertyData.ownerType || propertyData.radioSelection;
  const {cellNumber, workNumber, homeNumber} = mapPhoneNumberDetails(contactData?.phoneNumbers);
  const {propertyAddress, ownerAddress} = mapAddressDetails(propertyData);
  return {
    propertyOwner: {
      contactType: ownerType ? capitalize(ownerType) : 'Person',
      emailAddress1: contactData.email || '',
      displayName:
        contactData.firstName && contactData.lastName
          ? `${contactData.firstName} ${contactData.lastName} ${
              contactData.suffix ? suffixTypes[contactData.suffix] : ''
            }`.trim()
          : contactData.companyName
          ? contactData.companyName
          : '',
      primaryAddress: ownerAddress,
      cellNumber,
      workNumber,
      homeNumber,
    },
    propertyAddress,
    ownerAddressSameAsProperty: propertyData.ownerAddressSameAsProperty || false,
    damageDescription: propertyData.damage || '',
    propertyTypeSelection: propertyData.propertyTypeSelection || '',
    otherProperty: propertyData.otherProperty || '',
  };
};

export const mapPhoneNumberDetails = (phoneNumbers: any) => {
  let mappedNumbers: any = {};
  if (phoneNumbers) {
    phoneNumbers?.forEach((number: any) => {
      if (number.phoneType === 'mobile') {
        mappedNumbers.cellNumber = `${number.phoneNumber} ${
          number.phoneExtension ? `ext. ${number.phoneExtension}` : ''
        }`;
      }
      if (number.phoneType === 'work') {
        mappedNumbers.workNumber = `${number.phoneNumber} ${
          number.phoneExtension ? `ext. ${number.phoneExtension}` : ''
        }`;
      }
      if (number.phoneType === 'home') {
        mappedNumbers.homeNumber = `${number.phoneNumber} ${
          number.phoneExtension ? `ext. ${number.phoneExtension}` : ''
        }`;
      }
    });
  }
  return mappedNumbers;
};

export const mapAddressDetails = (propertyData: any) => {
  let addressDetails: any = {propertyAddress: {}, ownerAddress: {}};

  if (propertyData.propertyAddress) {
    addressDetails.propertyAddress = {
      addressLine1: propertyData.propertyAddress.address || '',
      city: propertyData.propertyAddress.city || '',
      state: propertyData.propertyAddress.state || '',
      postalCode: propertyData.propertyAddress.zipCode || '',
    };
  }

  if (propertyData.ownerAddress) {
    addressDetails.ownerAddress = {
      addressLine1: propertyData.ownerAddress.address || '',
      city: propertyData.ownerAddress.city || '',
      state: propertyData.ownerAddress.state || '',
      postalCode: propertyData.ownerAddress.zipCode || '',
    };
  }
  return addressDetails;
};

export const mapAdditionalNotes = (mapData: any, formData: any, ccData: any) => {
  let mappedData = mapData;
  if (formData?.additionalNotes) {
    let notes: any;
    if (ccData?.adjusterNote && ccData?.adjusterNote.length !== 0) {
      const notesIndex = ccData.adjusterNote.findIndex(
        (x: any) => x.subject === 'Additional Comments'
      );
      notes = notesIndex !== -1 ? ccData.adjusterNote[notesIndex] : {};
      mappedData.adjusterNote = [notes];
    }
    if (!notes) {
      mappedData.adjusterNote = [
        {
          subject: 'Additional Comments',
          body: formData.additionalNotes.notes || '',
        },
      ];
    }
  }
  return mappedData;
};

export const mapIncidents = (incidents: LabelValue[]) => {
  if (incidents) {
    return incidents.map(incident => incident.label).join(', ');
  }
  return '';
};

const mapYesNoUnkReturnValue = (value: any) => {
  return value === 'yes' || value === true;
};
