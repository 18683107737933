import React, {useState, useContext, useEffect} from 'react';
import {Grid, Box, Typography, makeStyles} from '@material-ui/core';
import {YesNoToggle} from '../../common/YesNoToggle/YesNoToggle';
import {useFormContext} from 'react-hook-form';
import {VehicleDetailsContext, VehicleTypes} from '../YourVehicleDetails/YourVehicleDetails';
import {UserPersonaSwitch} from '../../common/UserPersonaSwitch/UserPersonaSwitch';
import {YesNoUnk} from '../../common/YesNoUnk/YesNoUnk';
import {PhoneInput} from '../../common/PhoneInput/PhoneInput';
import {RadioOption, DynamicRadioList} from '../../common/DynamicRadioList/DynamicRadioList';
import {SATextField} from '../../common/TextField/TextField';
import {VehicleTypeSwitch} from '../VehicleTypeSwitch/VehicleTypeSwitch';
import {AddressSwitch} from '../../common/AddressSwitch/AddressSwitch';
import {getAddressSwitchProps, makePersonaCheck, personaSchemaSwitch} from '../../../utils/utils';
import {AGENT_AND_ASSOCIATE_PERSONAS, CustomerTypes} from '../../../commonTypes';
import {UserPersonaContext, useFeatureFlags} from '../../common/Providers/Providers';
import {phoneValidation} from '../../../validations';
import * as yup from 'yup';
import {SABox, SAIcon, SAIcons, SAIconSize, SATooltip} from '@saux/design-system-react';
import {
  phoneExtensionValidationRegex,
  suffixTypes,
} from '../../common/ContactInfo/ContactInfoModal/ContactInfoModal';

const allowedPersonas = [
  CustomerTypes.SaCustomer,
  CustomerTypes.ThirdParty,
  CustomerTypes.Associate,
  CustomerTypes.SaAgent,
];

export const VehicleDriveableSchema = {
  vehicleDriveableAdditionalInfo: yup.object().shape({
    phoneNumber: phoneValidation(true),
    phoneExtension: yup.string().when('phoneNumber', {
      is: (value: string) => !!value,
      then: yup
        .string()
        .matches(
          phoneExtensionValidationRegex,
          'Phone extension must be a numeric value of up to 4 digits.'
        )
        .nullable(),
      otherwise: yup
        .string()
        .test(
          'not-allowed',
          'Phone extension should be empty if no phone number provided.',
          function (value) {
            return !value;
          }
        )
        .nullable(),
    }),
    vehicleInCustomerPossession: personaSchemaSwitch({
      is: (value: any) => makePersonaCheck(value, allowedPersonas),
      then: yup
        .string()
        .oneOf(['yes', 'no', ''], 'Please Select the current vehicle possession')
        .nullable(),
      otherwise: yup.string().nullable(),
    }),
  }),
};

export enum VehicleLocationTypes {
  RepairShop = 'repairShop',
  TowYard = 'towYard',
  OtherBusiness = 'otherBusiness',
  OtherLocation = 'otherLocation',
}

export const VEHICLE_LOCATION_LABEL_TYPES: {[key: string]: string} = {
  [VehicleLocationTypes.RepairShop]: 'Repair Shop',
  [VehicleLocationTypes.TowYard]: 'Tow Yard',
  [VehicleLocationTypes.OtherBusiness]: 'Other Business',
  [VehicleLocationTypes.OtherLocation]: 'Other Location',
};

export const BUSINESS_VEHICLE_LOCATIONS = [
  VehicleLocationTypes.RepairShop,
  VehicleLocationTypes.TowYard,
  VehicleLocationTypes.OtherBusiness,
];

export const PERSONAL_VEHICLE_LOCATIONS = [VehicleLocationTypes.OtherLocation];

export const VEHICLE_LOCATION_OPTIONS: RadioOption[] = [
  {
    label: VEHICLE_LOCATION_LABEL_TYPES[VehicleLocationTypes.RepairShop],
    value: VehicleLocationTypes.RepairShop,
    testId: VehicleLocationTypes.RepairShop,
  },
  {
    label: VEHICLE_LOCATION_LABEL_TYPES[VehicleLocationTypes.TowYard],
    value: VehicleLocationTypes.TowYard,
    testId: VehicleLocationTypes.TowYard,
  },
  {
    label: VEHICLE_LOCATION_LABEL_TYPES[VehicleLocationTypes.OtherBusiness],
    value: VehicleLocationTypes.OtherBusiness,
    testId: VehicleLocationTypes.OtherBusiness,
  },
  {
    label: VEHICLE_LOCATION_LABEL_TYPES[VehicleLocationTypes.OtherLocation],
    value: VehicleLocationTypes.OtherLocation,
    testId: VehicleLocationTypes.OtherLocation,
  },
];

const useStyles = makeStyles(theme => ({
  streetAddressBox: {
    paddingRight: 0,
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(2),
    },
  },
  tooltipIcon: {
    marginTop: -12,
  },
  fieldset: {
    border: '0 none',
  },
}));

export const VehicleDriveable = () => {
  const {featureFlags} = useFeatureFlags();
  const formMethods = useFormContext();
  const {register, errors} = formMethods;
  const {fieldIndex, parentFieldName} = useContext(VehicleDetailsContext);

  const [isNotDriveable, setIsNotDriveable] = useState<null | boolean>(null);
  const changeHandler = (value: string, fieldName: string) => {
    setIsNotDriveable(value === 'no');
  };

  const [vehicleLocationSelection, setVehicleLocationSelection] = useState<string>('');
  const handleVehicleLocationChange = (value: string) => {
    setVehicleLocationSelection(value);
  };

  const [isNotInCustomerPossession, setIsNotInCustomerPossession] = useState<null | boolean>(null);
  const vehicleInCustomerPossessionChangeHandler = (value: string, fieldName: string) => {
    setIsNotInCustomerPossession(value === 'no');
  };

  const hasBusinessVehicleLocationSelected = BUSINESS_VEHICLE_LOCATIONS.includes(
    vehicleLocationSelection as VehicleLocationTypes
  );
  const hasPersonalVehicleLocationSelected = PERSONAL_VEHICLE_LOCATIONS.includes(
    vehicleLocationSelection as VehicleLocationTypes
  );
  const hasVehicleLocationSelected = vehicleLocationSelection && vehicleLocationSelection !== '';

  const classes = useStyles();

  const addressSwitchArgs = {
    parentFieldName: `${parentFieldName || ''}[${fieldIndex || 0}]`,
    errors: errors?.[`${parentFieldName || ''}`]?.[fieldIndex || 0],
  };

  const {manualAddressProps, autocompleteAddressProps} = getAddressSwitchProps(addressSwitchArgs);

  const {userPersona} = useContext(UserPersonaContext);

  const isAssociate = (userPersona as CustomerTypes) === CustomerTypes.Associate;
  const whosePossesion = isAssociate ? "the customer's" : 'your';

  useEffect(() => {
    setVehicleLocationSelection('');
  }, [isNotDriveable, isNotInCustomerPossession]);

  const displayVehicleDriveableAdditionalInfo = makePersonaCheck(
    userPersona as CustomerTypes,
    allowedPersonas
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box display="flex">
          <Typography variant="body1">Is the vehicle driveable?</Typography>
          <SATooltip
            wrapperTestId="title-test-wrapper"
            testId="title-test"
            content={
              <Box>
                <p>
                  Vehicle may not be safe to drive if any of the following are present but are not
                  limited to:
                </p>
                <SABox marginLeft="large">
                  <ul>
                    <li>Leaking fluids</li>
                    <li>Windows shattered </li>
                    <li>Airbags deployed</li>
                    <li>Headlights and/or taillights will not work</li>
                  </ul>
                </SABox>
              </Box>
            }
            variant="light"
            placement="top"
          >
            <SABox marginLeft="small">
              <SAIcon icon={SAIcons.help} size={SAIconSize.medium} colorVariant="dark" />
            </SABox>
          </SATooltip>
        </Box>
        <Box>
          <UserPersonaSwitch
            ifPersonas={AGENT_AND_ASSOCIATE_PERSONAS}
            then={
              <YesNoUnk
                dontKnowTestId="VehicleDriveableDontKnow"
                name={`${parentFieldName}[${fieldIndex}].vehicleDriveable`}
                onChange={changeHandler}
                noTestId="isDriveableRadio"
                yesTestId="YesVehicleIsDriveable"
                hasErrors={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                  'vehicleDriveable'
                )}
              />
            }
            otherwise={
              <VehicleTypeSwitch
                ifVehicleTypes={[VehicleTypes.OWNER]}
                then={
                  <YesNoToggle
                    testId="isDriveableToggle"
                    yesButtonTestId="YesVehicleIsDriveable"
                    name={`${parentFieldName}[${fieldIndex}].vehicleDriveable`}
                    handleChange={value =>
                      changeHandler(value, `${parentFieldName}[${fieldIndex}].vehicleDriveable`)
                    }
                    hasError={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                      'vehicleDriveable'
                    )}
                    errorMessage={
                      errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleDriveable?.message
                    }
                  />
                }
                otherwise={
                  <YesNoUnk
                    dontKnowTestId="VehicleDriveableDontKnow"
                    name={`${parentFieldName}[${fieldIndex}].vehicleDriveable`}
                    onChange={changeHandler}
                    noTestId="isDriveableRadio"
                    yesTestId="YesVehicleIsDriveable"
                  />
                }
              />
            }
          />
        </Box>
      </Grid>
      {displayVehicleDriveableAdditionalInfo && (
        <>
          <Grid item xs={12}>
            <Box pt={2}>
              <Typography variant="body1">
                Is the vehicle currently in {whosePossesion} possession?
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box pt={2}>
              <YesNoUnk
                dontKnowTestId="VehicleInCustomerPossessionDontKnow"
                name={`${parentFieldName}[${fieldIndex}].vehicleDriveableAdditionalInfo.vehicleInCustomerPossession`}
                onChange={vehicleInCustomerPossessionChangeHandler}
                noTestId="isVehicleInCustomerPossessionRadio"
                yesTestId="YesVehicleInCustomerPossession"
                hasErrors={errors?.[parentFieldName || '']?.[
                  fieldIndex || 0
                ]?.vehicleDriveableAdditionalInfo?.hasOwnProperty('vehicleInCustomerPossession')}
                errorMessage={
                  errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleDriveableAdditionalInfo
                    ?.vehicleInCustomerPossession?.message || ''
                }
              />
            </Box>
          </Grid>
          {isNotInCustomerPossession && (
            <>
              <fieldset className={classes.fieldset}>
                <Grid item xs={12}>
                  <legend>
                    <Box pt={2}>
                      <Typography variant="body1">Where is the vehicle located?</Typography>
                    </Box>
                  </legend>
                </Grid>
                <Grid item xs={12}>
                  <Box pt={2}>
                    <DynamicRadioList
                      name={`${parentFieldName}[${fieldIndex}].vehicleDriveableAdditionalInfo.vehicleLocationSelection`}
                      defaultValue=""
                      options={VEHICLE_LOCATION_OPTIONS}
                      onChange={handleVehicleLocationChange}
                    />
                  </Box>
                </Grid>
              </fieldset>
              <Grid item xs={12}>
                <Grid item xs={12} sm={6} md={5}>
                  {hasBusinessVehicleLocationSelected && (
                    <>
                      <Box pt={2}>
                        <SATextField
                          hasWhiteFields
                          id={`vehicleLocationCompanyName-${parentFieldName}[${fieldIndex}]`}
                          label="Business Name"
                          inputRef={register()}
                          name={`${parentFieldName}[${fieldIndex}].vehicleDriveableAdditionalInfo.companyName`}
                          characterLimit={30}
                          InputLabelProps={{
                            'aria-labelledby': `vehicleLocationCompanyName-${parentFieldName}[${fieldIndex}]`,
                          }}
                          error={errors?.[parentFieldName || '']?.[
                            fieldIndex || 0
                          ]?.vehicleDriveableAdditionalInfo?.hasOwnProperty('companyName')}
                          helperText={
                            errors?.[parentFieldName || '']?.[fieldIndex || 0]
                              ?.vehicleDriveableAdditionalInfo?.companyName?.message
                          }
                        />
                      </Box>
                    </>
                  )}
                  {hasPersonalVehicleLocationSelected && (
                    <>
                      <Box pt={2}>
                        <SATextField
                          hasWhiteFields
                          id={`vehicleLocationFirstName-${parentFieldName}[${fieldIndex}]`}
                          label="First Name"
                          inputRef={register()}
                          name={`${parentFieldName}[${fieldIndex}].vehicleDriveableAdditionalInfo.firstName`}
                          characterLimit={30}
                          InputLabelProps={{
                            'aria-labelledby': `vehicleLocationFirstName-${parentFieldName}[${fieldIndex}]`,
                          }}
                          error={errors?.[parentFieldName || '']?.[
                            fieldIndex || 0
                          ]?.vehicleDriveableAdditionalInfo?.hasOwnProperty('firstName')}
                          helperText={
                            errors?.[parentFieldName || '']?.[fieldIndex || 0]
                              ?.vehicleDriveableAdditionalInfo?.firstName?.message
                          }
                        />
                      </Box>
                      <Box pt={2}>
                        <SATextField
                          hasWhiteFields
                          id={`vehicleLocationLastName-${parentFieldName}[${fieldIndex}]`}
                          label="Last Name"
                          inputRef={register()}
                          name={`${parentFieldName}[${fieldIndex}].vehicleDriveableAdditionalInfo.lastName`}
                          characterLimit={30}
                          InputLabelProps={{
                            'aria-labelledby': `vehicleLocationLastName-${parentFieldName}[${fieldIndex}]`,
                          }}
                          error={errors?.[parentFieldName || '']?.[
                            fieldIndex || 0
                          ]?.vehicleDriveableAdditionalInfo?.hasOwnProperty('lastName')}
                          helperText={
                            errors?.[parentFieldName || '']?.[fieldIndex || 0]
                              ?.vehicleDriveableAdditionalInfo?.lastName?.message
                          }
                        />
                      </Box>
                      {featureFlags.FF_DCARE_6770 && (
                        <Box pt={2}>
                          <SATextField
                            hasWhiteFields
                            id={`vehicleLocationSuffix-${parentFieldName}[${fieldIndex}]`}
                            label="Suffix"
                            inputRef={register()}
                            name={`${parentFieldName}[${fieldIndex}].vehicleDriveableAdditionalInfo.suffix`}
                            select
                            SelectProps={{native: true}}
                            InputLabelProps={{
                              'aria-labelledby': `vehicleLocationSuffix-${parentFieldName}[${fieldIndex}]`,
                            }}
                            error={errors?.[parentFieldName || '']?.[
                              fieldIndex || 0
                            ]?.vehicleDriveableAdditionalInfo?.hasOwnProperty('suffix')}
                            helperText={
                              errors?.[parentFieldName || '']?.[fieldIndex || 0]
                                ?.vehicleDriveableAdditionalInfo?.suffix?.message
                            }
                          >
                            <option aria-label="None" value="" />
                            {Object.keys(suffixTypes).map((key: string) => (
                              <option value={key}>{suffixTypes[key]}</option>
                            ))}
                          </SATextField>
                        </Box>
                      )}
                    </>
                  )}
                  {hasVehicleLocationSelected && (
                    <>
                      <Box pt={2}>
                        <PhoneInput
                          hasWhiteFields
                          hasError={errors?.[parentFieldName || '']?.[
                            fieldIndex || 0
                          ]?.vehicleDriveableAdditionalInfo?.hasOwnProperty('phoneNumber')}
                          name={`${parentFieldName}[${fieldIndex}].vehicleDriveableAdditionalInfo.phoneNumber`}
                          id="phone-vehicle-location"
                          InputLabelProps={{'aria-labelledby': 'phone-vehicle-location'}}
                          helperText={
                            errors?.[parentFieldName || '']?.[fieldIndex || 0]
                              ?.vehicleDriveableAdditionalInfo?.phoneNumber?.message
                          }
                          label={
                            hasBusinessVehicleLocationSelected
                              ? `Business Phone Number`
                              : `Phone Number`
                          }
                          inputRef={register({
                            required: false,
                            pattern: {
                              value: /^(\(\d{3}\))\s(\d{3})-(\d{4})$/,
                              message: 'Enter as (###) ###-####',
                            },
                          })}
                          phoneExtensionProps={{
                            showPhoneExtension: true,
                            name: `${parentFieldName}[${fieldIndex}].vehicleDriveableAdditionalInfo.phoneExtension`,
                            id: 'phone-vehicle-extension',
                            hasError:
                              errors?.[parentFieldName || '']?.[
                                fieldIndex || 0
                              ]?.vehicleDriveableAdditionalInfo?.hasOwnProperty('phoneExtension'),
                            helperText:
                              errors?.[parentFieldName || '']?.[fieldIndex || 0]
                                ?.vehicleDriveableAdditionalInfo?.phoneExtension?.message,
                            label: hasBusinessVehicleLocationSelected
                              ? `Business Phone Extension`
                              : `Phone Extension`,
                            inputRef: register({
                              required: false,
                              pattern: {
                                value: phoneExtensionValidationRegex,
                                message:
                                  'Phone extension must be a numeric value of up to 4 digits.',
                              },
                            }),
                          }}
                        />
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>
              <AddressSwitch
                manualAddressProps={manualAddressProps}
                autocompleteAddressProps={autocompleteAddressProps}
                parentFieldName={`${parentFieldName}[${fieldIndex}]`}
                mdWidth={5}
              />
            </>
          )}
        </>
      )}
      {isNotDriveable && (
        <>
          {!displayVehicleDriveableAdditionalInfo && (
            <>
              <Grid item xs={12}>
                <Box pt={2}>
                  <Typography variant="body1">Location of the vehicle</Typography>
                </Box>
              </Grid>
              <AddressSwitch
                manualAddressProps={manualAddressProps}
                autocompleteAddressProps={autocompleteAddressProps}
                parentFieldName={`${parentFieldName}[${fieldIndex}]`}
              />
            </>
          )}
          <VehicleTypeSwitch
            ifVehicleTypes={[VehicleTypes.OWNER]}
            then={
              <Grid item xs={12}>
                <Box pt={2}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="body1">Did the airbags deploy?</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box pt={1} pb={2}>
                        <UserPersonaSwitch
                          ifPersonas={AGENT_AND_ASSOCIATE_PERSONAS}
                          then={
                            <YesNoUnk
                              dontKnowTestId="AirbagsDeployedDontKnow"
                              noTestId="no-airbags-deployed"
                              yesTestId="yes-airbags-deployed"
                              name={`${parentFieldName}[${fieldIndex}].airbagsDeployed`}
                            />
                          }
                          otherwise={
                            <>
                              <YesNoToggle
                                name={`${parentFieldName}[${fieldIndex}].airbagsDeployed`}
                                hasError={errors?.[parentFieldName || '']?.[
                                  fieldIndex || 0
                                ]?.hasOwnProperty('airbagsDeployed')}
                                testId="no-airbags-deployed"
                                yesButtonTestId="yes-airbags-deployed"
                                errorMessage={
                                  errors?.[parentFieldName || '']?.[fieldIndex || 0]
                                    ?.airbagsDeployed?.message
                                }
                              />
                            </>
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            }
            otherwise={
              <input
                type="hidden"
                value=""
                name={`${parentFieldName}[${fieldIndex}].airbagsDeployed`}
                ref={register()}
              />
            }
          />
          <VehicleTypeSwitch
            ifVehicleTypes={[VehicleTypes.OWNER]}
            then={
              <Grid item xs={12}>
                <Box>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        Did the car rollover during the incident?
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box pt={1} pb={2}>
                        <UserPersonaSwitch
                          ifPersonas={AGENT_AND_ASSOCIATE_PERSONAS}
                          then={
                            <YesNoUnk
                              dontKnowTestId="CarRolledOverDontKnow"
                              name={`${parentFieldName}[${fieldIndex}].vehicleRolledOver`}
                            />
                          }
                          otherwise={
                            <YesNoToggle
                              name={`${parentFieldName}[${fieldIndex}].vehicleRolledOver`}
                            />
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            }
            otherwise={
              <input
                type="hidden"
                value=""
                name={`${parentFieldName}[${fieldIndex}].vehicleRolledOver`}
                ref={register()}
              />
            }
          />
          <VehicleTypeSwitch
            ifVehicleTypes={[VehicleTypes.OWNER]}
            then={
              <Grid item xs={12}>
                <Box>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        Was there any water damage up to the engine?
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box pt={1} pb={2}>
                        <UserPersonaSwitch
                          ifPersonas={AGENT_AND_ASSOCIATE_PERSONAS}
                          then={
                            <YesNoUnk
                              dontKnowTestId="WaterDamageEngineDontKnow"
                              name={`${parentFieldName}[${fieldIndex}].vehicleHasEngineWaterDamage`}
                            />
                          }
                          otherwise={
                            <YesNoToggle
                              name={`${parentFieldName}[${fieldIndex}].vehicleHasEngineWaterDamage`}
                            />
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            }
            otherwise={
              <input
                type="hidden"
                value=""
                name={`${parentFieldName}[${fieldIndex}].vehicleHasEngineWaterDamage`}
                ref={register()}
              />
            }
          />
          <VehicleTypeSwitch
            ifVehicleTypes={[VehicleTypes.OWNER]}
            then={
              <Grid item xs={12}>
                <Box>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        <UserPersonaSwitch
                          ifPersonas={[
                            CustomerTypes.SaAgent,
                            CustomerTypes.Guardian,
                            CustomerTypes.Associate,
                          ]}
                          then={'Does the insured already have a rental?'}
                          otherwise={'Do you already have a rental vehicle?'}
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box py={1}>
                        <UserPersonaSwitch
                          ifPersonas={AGENT_AND_ASSOCIATE_PERSONAS}
                          then={
                            <YesNoUnk
                              dontKnowTestId="DoYouHaveARentalDontKnow"
                              yesTestId="test-yes-have-rental"
                              noTestId="test-no-have-rental"
                              name={`${parentFieldName}[${fieldIndex}].doYouHaveARental`}
                            />
                          }
                          otherwise={
                            <>
                              <YesNoToggle
                                name={`${parentFieldName}[${fieldIndex}].doYouHaveARental`}
                                yesButtonTestId="test-yes-have-rental"
                                testId="test-no-have-rental"
                                hasError={errors?.[parentFieldName || '']?.[
                                  fieldIndex || 0
                                ]?.hasOwnProperty('doYouHaveARental')}
                                errorMessage={
                                  errors?.[parentFieldName || '']?.[fieldIndex || 0]
                                    ?.doYouHaveARental?.message
                                }
                              />
                            </>
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            }
            otherwise={
              <input
                type="hidden"
                value=""
                name={`${parentFieldName}[${fieldIndex}].doYouHaveARental`}
                ref={register()}
              />
            }
          />
        </>
      )}
    </Grid>
  );
};
