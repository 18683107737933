import React from 'react';
import {Box, Typography, Grid} from '@material-ui/core';
import {capitalize, formatPhone} from '../../../utils/utils';
import {BlueContainer} from '../../common/Containers/Containers';
import {SummaryField} from '../../common/Summary/components/SummaryField/SummaryField';
import {SummaryHeader} from '../../common/Summary/components/SummaryHeader/SummaryHeader';
import {Contact, Official} from '../../common/Summary/types';
import {PdfSection} from '../../common/Summary/components/PdfSection/PdfSection';
import {SUMMARY_PDF_ID} from '../../pdf/constants';
import {
  RegisterBreadcrumb,
  WaypointNames,
  WAYPOINTS,
} from '../../common/RegisterBreadcrumb/RegisterBreadcrumb';
import {fireReportTypes, policeReportTypes} from '../OtherDetails/OtherDetails';

export interface OtherDetailsSummaryData {
  witnesses?: Contact[];
  officials?: Official[];
}

export const OtherDetailsSummary = (props: OtherDetailsSummaryData) => {
  return (
    <RegisterBreadcrumb
      waypointName={WaypointNames.OtherDetails}
      displayName={WAYPOINTS[WaypointNames.OtherDetails].displayName}
    >
      <Box>
        <PdfSection className={SUMMARY_PDF_ID}>
          <>
            <SummaryHeader headerText="Other Details" />
            {props?.officials && props?.officials?.length > 0 && (
              <Box py={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <SummaryField label="Which first responders were at the scene?">
                      <>
                        {props?.officials?.map((official: Official) => (
                          <Typography>{capitalize(official?.officialType || '')}</Typography>
                        ))}
                      </>
                    </SummaryField>
                  </Grid>
                  {props?.officials?.map((official: Official) => (
                    <Grid container spacing={3} item xs={12}>
                      <>
                        {official.officialType === 'fire' && official?.officialReportType && (
                          <Grid item xs={6} md={4}>
                            <SummaryField label="Fire Report Type">
                              <Typography>
                                {fireReportTypes[official.officialReportType] || ''}
                              </Typography>
                            </SummaryField>
                          </Grid>
                        )}
                        {official.officialType === 'police' && official?.officialReportType && (
                          <Grid item xs={6} md={4}>
                            <SummaryField label="Police Report Type">
                              <Typography>
                                {policeReportTypes[official.officialReportType] || ''}
                              </Typography>
                            </SummaryField>
                          </Grid>
                        )}
                      </>
                      {official.reportNumber && (
                        <Grid item xs={6} md={4}>
                          <SummaryField
                            label={`${capitalize(official?.officialType || '')} Report Number`}
                          >
                            <Typography>{official.reportNumber}</Typography>
                          </SummaryField>
                        </Grid>
                      )}
                      <Grid item xs={6} md={4}>
                        <SummaryField label="Department Name">
                          <Typography>{official.officialName}</Typography>
                        </SummaryField>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </>
        </PdfSection>
        {props?.witnesses && props?.witnesses?.length > 0 && (
          <PdfSection className={SUMMARY_PDF_ID}>
            <BlueContainer>
              <SummaryHeader forBlueContainer headerText="Witness Details" />
              <Box pt={3}>
                {props?.witnesses?.map((witness: Contact) => (
                  <Grid container spacing={3}>
                    <Grid item xs={6} md={4}>
                      <SummaryField label="Name">
                        <Typography>{witness.displayName}</Typography>
                      </SummaryField>
                    </Grid>
                    {witness.cellNumber && (
                      <Grid item xs={6} md={4}>
                        <SummaryField label="Mobile Number">
                          <Typography>{formatPhone(witness.cellNumber)}</Typography>
                        </SummaryField>
                      </Grid>
                    )}
                    {witness.workNumber && (
                      <Grid item xs={6} md={4}>
                        <SummaryField label="Work Number">
                          <Typography>{formatPhone(witness.workNumber)}</Typography>
                        </SummaryField>
                      </Grid>
                    )}
                    {witness.homeNumber && (
                      <Grid item xs={6} md={4}>
                        <SummaryField label="Home Number">
                          <Typography>{formatPhone(witness.homeNumber)}</Typography>
                        </SummaryField>
                      </Grid>
                    )}
                  </Grid>
                ))}
              </Box>
            </BlueContainer>
          </PdfSection>
        )}
      </Box>
    </RegisterBreadcrumb>
  );
};
