import React, {useEffect} from 'react';
import {Box, Grid, Typography} from '@material-ui/core';
import {GeneralDetailsSummary} from '../../auto/GeneralDetailsSummary/GeneralDetailsSummary';
import {OtherDetailsSummary} from '../../auto/OtherDetailsSummary/OtherDetailsSummary';
import {PropertyDamageDetailsSummary} from '../../auto/PropertyDamageDetailsSummary/PropertyDamageDetailsSummary';
import {InsuredContactSummary} from '../../homeowners/InsuredContactSummary/InsuredContactSummary';
import {IncidentDetailsSummary} from '../../auto/IncidentDetailsSummary/IncidentDetailsSummary';
import {AdditionalNotesSummary} from '../../auto/AdditionalNotesSummary/AdditionalNotesSummary';
import {initCap, isEmptyArray, joinMappedArray} from '../../../utils/utils';
import {
  IncidentTypes,
  INCIDENT_NATURE_OF_LOSS_MAPPING,
  NatureOfLoss,
} from '../../common/Incidents/Incidents';
import {StateProps, SubmissionTypes} from '../../common/Summary/types';
import {GreyBorderedBox} from '../../common/Summary/components/GreyBorderedBox/GreyBorderedBox';
import {SummaryField} from '../../common/Summary/components/SummaryField/SummaryField';
import {SummaryHeader} from '../../common/Summary/components/SummaryHeader/SummaryHeader';
import {AnyoneElseInjuredSummary} from '../../auto/AnyoneElseInjuredSummary/AnyoneElseInjuredSummary';
import {SelectedIncidents} from './SelectedIncidents/SelectedIncidents';
import Cookies from 'js-cookie';
import {PdfSection} from '../../common/Summary/components/PdfSection/PdfSection';
import {SUMMARY_PDF_ID} from '../../pdf/constants';
import {AddressSummary} from '../../auto/VehicleSummary/AddressSummary';
import {MultiplePhoneNumbersSummary} from '../../common/Summary/components/MultiplePhoneNumbersSummary/MultiplePhoneNumbersSummary';
import {ConditionalWaypoint, insertConditionalBreadcrumbs} from '../../../pages/utils';
import {
  RegisterBreadcrumb,
  WaypointNames,
  WAYPOINTS,
} from '../../common/RegisterBreadcrumb/RegisterBreadcrumb';

interface HomeownersSuccessProps {
  successState: StateProps;
  setWaypoints?: any;
}

export const HomeownersSuccess = ({successState, setWaypoints}: HomeownersSuccessProps) => {
  const lob = successState?.lobs?.homeowners;
  const injuryIncidents = lob?.injuryIncidents || [];
  const fixedPropertyIncidents = lob?.fixedPropertyIncidents || lob?.propertyIncidents || [];
  const userNote = successState?.adjusterNote?.find(x => x.subject === 'Additional Comments')?.body;
  const homeownersIncidents = successState?.formData?.incidentDetails;
  const isWeatherDamage =
    homeownersIncidents?.natureOfLoss ===
    INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][
      NatureOfLoss.WeatherRelated
    ];
  const isFireDamage =
    homeownersIncidents?.natureOfLoss ===
      INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][NatureOfLoss.Fire] &&
    homeownersIncidents?.fireDamageDetailsRadioGroup;

  const isWaterDamage =
    homeownersIncidents?.natureOfLoss ===
      INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][NatureOfLoss.Water] &&
    (homeownersIncidents?.waterDamageSource || homeownersIncidents?.hasAContractorBeenHired);
  const insuredContactInfo = successState?.formData?.insuredContactInformation;
  const policyNumber = Cookies.get('policyNumber');
  const contractorSelected = homeownersIncidents?.hasAContractorBeenHired;

  useEffect(() => {
    if (setWaypoints) {
      //Setting base waypoints for the auto page
      const baseWaypoints = [
        WaypointNames.GeneralDetails,
        WaypointNames.IncidentDetails,
        WaypointNames.AnyoneElseInjured,
        WaypointNames.PropertyDamageDetails,
      ];

      //getting the number of additions including current and previous conditional waypoints
      //this helps to calculate where to splice the waypoint
      const insuredContactInfoSpliceStart = !!insuredContactInfo ? 1 : 0;
      const weatherFireOrWaterDamageDetailsSpliceStart = !!(
        homeownersIncidents &&
        (isWeatherDamage || isFireDamage || isWaterDamage)
      )
        ? 1 + insuredContactInfoSpliceStart
        : insuredContactInfoSpliceStart;

      //setting up the waypoints conditional on the page
      const conditionalWaypoints: ConditionalWaypoint[] = [
        {
          condition: !!insuredContactInfo,
          spliceStart: 1,
          waypoint: WaypointNames.InsuredContactDetails,
        },
        {
          condition: !!(homeownersIncidents && isWeatherDamage),
          spliceStart: 2 + insuredContactInfoSpliceStart,
          waypoint: WaypointNames.WeatherDamageDetails,
        },
        {
          condition: !!(homeownersIncidents && isFireDamage),
          spliceStart: 2 + insuredContactInfoSpliceStart,
          waypoint: WaypointNames.FireDamageDetails,
        },
        {
          condition: !!(homeownersIncidents && isWaterDamage),
          spliceStart: 2 + insuredContactInfoSpliceStart,
          waypoint: WaypointNames.WaterDamageDetails,
        },
        {
          condition: !!(successState.officials || successState.witnesses),
          spliceStart: 3 + weatherFireOrWaterDamageDetailsSpliceStart,
          waypoint: WaypointNames.OtherDetails,
        },
        {
          condition: !!userNote,
          spliceStart: 'last',
          waypoint: WaypointNames.AdditionalNotes,
        },
      ];

      //setting the waypoints state so that they can be passed to BaseSummary to be rendered on sidebar
      setWaypoints(insertConditionalBreadcrumbs(baseWaypoints, conditionalWaypoints));
    }
  }, []);

  return (
    <>
      <PdfSection className={SUMMARY_PDF_ID}>
        <GreyBorderedBox hideBorderTop>
          <GeneralDetailsSummary
            mainContact={successState.mainContact}
            customerType={successState.customerType}
            lossLocation={successState.lossLocation}
            submissionType={successState.submissionType}
            contacts={successState.contacts}
            policyNumber={policyNumber}
            incidentTime={successState?.formData?.generalDetails?.lossTime}
            lossDate={{
              dateOfLoss: `${successState.lossDate}`,
              isDateOfLossKnown: successState?.formData?.generalDetails?.dateOfLossUnknown || false,
            }}
            howReported={successState?.formData?.gettingStarted?.howReported}
            fraudLanguage={successState?.formData?.generalDetails?.fraudLanguage}
            lawsuitClaim={successState?.formData?.generalDetails?.lawsuitClaim}
            claimNumber={successState?.claimNumber}
            isNoticeOnly={successState?.formData?.noticeOnly}
            lossTimezone={successState?.formData?.generalDetails?.lossTimezone}
          />
        </GreyBorderedBox>
      </PdfSection>
      {insuredContactInfo && (
        <PdfSection className={SUMMARY_PDF_ID}>
          <InsuredContactSummary insuredInformation={{...insuredContactInfo}} />
        </PdfSection>
      )}
      <PdfSection className={SUMMARY_PDF_ID}>
        <GreyBorderedBox>
          <IncidentDetailsSummary
            selectedIncidents={successState.selectedIncidents}
            incidentDescription={successState.incidentDescription}
            description={successState.description}
          />
        </GreyBorderedBox>
      </PdfSection>
      {homeownersIncidents && isWeatherDamage && (
        <PdfSection className={SUMMARY_PDF_ID}>
          <RegisterBreadcrumb
            waypointName={WaypointNames.WeatherDamageDetails}
            displayName={WAYPOINTS[WaypointNames.WeatherDamageDetails].displayName}
          >
            <GreyBorderedBox>
              <>
                <SummaryHeader headerText={'Weather Damage Details'} />
                {!isEmptyArray(homeownersIncidents.propertyTypes) && (
                  <Box pb={3}>
                    <SelectedIncidents
                      selectedIncidents={joinMappedArray(homeownersIncidents?.propertyTypes)}
                      header="What was damaged?"
                    />
                  </Box>
                )}
                {!isEmptyArray(homeownersIncidents.whereDamageToHomeIs) && (
                  <>
                    <Box pb={3}>
                      <SelectedIncidents
                        selectedIncidents={joinMappedArray(
                          homeownersIncidents?.whereDamageToHomeIs
                        )}
                        header="Where is the damage to the home?"
                      />
                    </Box>
                    {homeownersIncidents.wasDamageCausedByTreeOrLimb && (
                      <Box pb={3}>
                        <SummaryField label="Was the damage caused by a tree or tree limb?">
                          <Typography>
                            {initCap(homeownersIncidents.wasDamageCausedByTreeOrLimb)}
                          </Typography>
                        </SummaryField>
                      </Box>
                    )}
                    {homeownersIncidents.isTreeLimbOnTheRoof && (
                      <Box pb={3}>
                        <SummaryField label="Is the tree/limb on the roof?">
                          <Typography>
                            {initCap(homeownersIncidents.isTreeLimbOnTheRoof)}
                          </Typography>
                        </SummaryField>
                      </Box>
                    )}
                    {!isEmptyArray(homeownersIncidents.whatWasDamagedToTheExteriorOfTheHome) && (
                      <Box pb={3}>
                        <SelectedIncidents
                          selectedIncidents={joinMappedArray(
                            homeownersIncidents?.whatWasDamagedToTheExteriorOfTheHome
                          )}
                          header="What was damaged on the exterior of the home?"
                        />
                      </Box>
                    )}
                    {homeownersIncidents.haveTemporaryRepairsBeenMadeToTheRoof && (
                      <Box pb={3}>
                        <SummaryField label="Have temporary repairs been made to the roof?">
                          <Typography>
                            {initCap(homeownersIncidents.haveTemporaryRepairsBeenMadeToTheRoof)}
                          </Typography>
                        </SummaryField>
                      </Box>
                    )}
                    {!isEmptyArray(homeownersIncidents.whatWasDamagedToTheInteriorOfTheHome) && (
                      <Box pb={3}>
                        <SelectedIncidents
                          selectedIncidents={joinMappedArray(
                            homeownersIncidents?.whatWasDamagedToTheInteriorOfTheHome
                          )}
                          header="What was damaged in the interior of the home?"
                        />
                      </Box>
                    )}
                    {!isEmptyArray(homeownersIncidents.whichRoomsWereDamagedOnTheFirstFloor) && (
                      <Box pb={3}>
                        <SelectedIncidents
                          selectedIncidents={joinMappedArray(
                            homeownersIncidents?.whichRoomsWereDamagedOnTheFirstFloor
                          )}
                          header="Which rooms were damaged on the 1st floor?"
                        />
                      </Box>
                    )}
                    {!isEmptyArray(homeownersIncidents.whichRoomsWereDamagedOnTheSecondFloor) && (
                      <Box pb={3}>
                        <SelectedIncidents
                          selectedIncidents={joinMappedArray(
                            homeownersIncidents?.whichRoomsWereDamagedOnTheSecondFloor
                          )}
                          header="Which rooms were damaged on the 2nd floor?"
                        />
                      </Box>
                    )}
                    {!isEmptyArray(homeownersIncidents.whichRoomsWereDamagedInTheBasement) && (
                      <Box pb={3}>
                        <SelectedIncidents
                          selectedIncidents={joinMappedArray(
                            homeownersIncidents?.whichRoomsWereDamagedInTheBasement
                          )}
                          header="Which rooms were damaged in the basement?"
                        />
                      </Box>
                    )}
                  </>
                )}
                {homeownersIncidents.isPropertyHabitable && (
                  <Box pb={3}>
                    <SummaryField label="Is the house or dwelling still livable due to the resulting damage?">
                      <Typography>{initCap(homeownersIncidents.isPropertyHabitable)}</Typography>
                    </SummaryField>
                  </Box>
                )}
                {!isEmptyArray(homeownersIncidents.emergencyServices) && (
                  <Box pb={3}>
                    <SelectedIncidents
                      selectedIncidents={joinMappedArray(homeownersIncidents?.emergencyServices)}
                      header="Are any of the following emergency services needed as a result of the damages?"
                    />
                  </Box>
                )}
                {contractorSelected && (
                  <Box pb={3}>
                    <SummaryField label="Has a Contractor been selected?">
                      <Typography>{initCap(contractorSelected)}</Typography>
                    </SummaryField>
                    <Box pt={3}>
                      {contractorSelected === 'yes' && (
                        <>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={4}>
                              <SummaryField label="Contractor Name">
                                <Typography>
                                  {initCap(`${homeownersIncidents.contractor?.contractorName}`)}
                                </Typography>
                              </SummaryField>
                            </Grid>
                            {homeownersIncidents.contractor?.phoneNumbers &&
                              homeownersIncidents.contractor?.phoneNumbers?.length > 0 && (
                                <Grid item xs={12} sm={6} md={4}>
                                  <MultiplePhoneNumbersSummary
                                    phoneNumbers={homeownersIncidents.contractor.phoneNumbers}
                                  />
                                </Grid>
                              )}
                            {homeownersIncidents.contractor?.email && (
                              <Grid item xs={12} sm={6} md={4}>
                                <SummaryField label="Contractor Email">
                                  <Typography>{`${homeownersIncidents.contractor?.email} `}</Typography>
                                </SummaryField>
                              </Grid>
                            )}
                            {homeownersIncidents.contractor?.address && (
                              <Grid item xs={12} sm={6} md={5}>
                                <AddressSummary
                                  label={'Contractor Address'}
                                  addressLine1={homeownersIncidents.contractor.address}
                                  city={homeownersIncidents.contractor.city}
                                  postalCode={homeownersIncidents.contractor.zipCode}
                                  state={homeownersIncidents.contractor.state}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </>
                      )}
                    </Box>
                  </Box>
                )}
              </>
            </GreyBorderedBox>
          </RegisterBreadcrumb>
        </PdfSection>
      )}
      {homeownersIncidents && isFireDamage && (
        <PdfSection className={SUMMARY_PDF_ID}>
          <RegisterBreadcrumb
            waypointName={WaypointNames.FireDamageDetails}
            displayName={WAYPOINTS[WaypointNames.FireDamageDetails].displayName}
          >
            <GreyBorderedBox>
              <>
                <SummaryHeader headerText={'Fire Damage Details'} />
                {!isEmptyArray(homeownersIncidents.propertyTypes) && (
                  <Box pb={3}>
                    <SelectedIncidents
                      selectedIncidents={joinMappedArray(homeownersIncidents?.propertyTypes)}
                      header="What was damaged?"
                    />
                  </Box>
                )}
                {homeownersIncidents.fireDamageDetailsRadioGroup && (
                  <Box pb={3} pt={3}>
                    <SummaryField label="What best describes the damages?">
                      <Typography>
                        {initCap(homeownersIncidents.fireDamageDetailsRadioGroup)}
                      </Typography>
                    </SummaryField>
                  </Box>
                )}
                {!isEmptyArray(homeownersIncidents.whichAreasHaveSmokeDamageOnly) && (
                  <Box pb={3}>
                    <SelectedIncidents
                      selectedIncidents={joinMappedArray(
                        homeownersIncidents?.whichAreasHaveSmokeDamageOnly
                      )}
                      header="What areas have smoke damage only?"
                    />
                  </Box>
                )}
                {!isEmptyArray(
                  homeownersIncidents.whichRoomsDamagedMainLevelAreaSmokeDamageOnly
                ) && (
                  <Box pb={3}>
                    <SelectedIncidents
                      selectedIncidents={joinMappedArray(
                        homeownersIncidents?.whichRoomsDamagedMainLevelAreaSmokeDamageOnly
                      )}
                      header="Which rooms were damaged on the main level?"
                    />
                  </Box>
                )}
                {!isEmptyArray(
                  homeownersIncidents.whichRoomsDamagedUpperLevelAreaSmokeDamageOnly
                ) && (
                  <Box pb={3}>
                    <SelectedIncidents
                      selectedIncidents={joinMappedArray(
                        homeownersIncidents?.whichRoomsDamagedUpperLevelAreaSmokeDamageOnly
                      )}
                      header="Which rooms were damaged on the upper level?"
                    />
                  </Box>
                )}
                {!isEmptyArray(
                  homeownersIncidents.whichRoomsDamagedBasementAreaSmokeDamageOnly
                ) && (
                  <Box pb={3}>
                    <SelectedIncidents
                      selectedIncidents={joinMappedArray(
                        homeownersIncidents?.whichRoomsDamagedBasementAreaSmokeDamageOnly
                      )}
                      header="Which rooms were damaged in the basement?"
                    />
                  </Box>
                )}
                {!isEmptyArray(homeownersIncidents.whichAreasWereDamagedByFire) && (
                  <Box pb={3}>
                    <SelectedIncidents
                      selectedIncidents={joinMappedArray(
                        homeownersIncidents?.whichAreasWereDamagedByFire
                      )}
                      header="What areas were damaged by fire?"
                    />
                  </Box>
                )}
                {!isEmptyArray(homeownersIncidents.whichRoomsDamagedMainLevelAreaDamagedByFire) && (
                  <Box pb={3}>
                    <SelectedIncidents
                      selectedIncidents={joinMappedArray(
                        homeownersIncidents?.whichRoomsDamagedMainLevelAreaDamagedByFire
                      )}
                      header="Which rooms were damaged on the main level?"
                    />
                  </Box>
                )}
                {!isEmptyArray(
                  homeownersIncidents.whichRoomsDamagedUpperLevelAreaDamagedByFire
                ) && (
                  <Box pb={3}>
                    <SelectedIncidents
                      selectedIncidents={joinMappedArray(
                        homeownersIncidents?.whichRoomsDamagedUpperLevelAreaDamagedByFire
                      )}
                      header="Which rooms were damaged on the upper level?"
                    />
                  </Box>
                )}
                {!isEmptyArray(homeownersIncidents.whichRoomsDamagedBasementAreaDamagedByFire) && (
                  <Box pb={3}>
                    <SelectedIncidents
                      selectedIncidents={joinMappedArray(
                        homeownersIncidents?.whichRoomsDamagedBasementAreaDamagedByFire
                      )}
                      header="Which rooms were damaged in the basement?"
                    />
                  </Box>
                )}
                {homeownersIncidents.hasAContractorBeenHired && (
                  <Box pb={3}>
                    <SummaryField label="Has a contactor already been hired?">
                      <Typography>
                        {initCap(homeownersIncidents.hasAContractorBeenHired)}
                      </Typography>
                    </SummaryField>
                  </Box>
                )}
              </>
            </GreyBorderedBox>
          </RegisterBreadcrumb>
        </PdfSection>
      )}
      {homeownersIncidents && isWaterDamage && (
        <PdfSection className={SUMMARY_PDF_ID}>
          <RegisterBreadcrumb
            waypointName={WaypointNames.WaterDamageDetails}
            displayName={WAYPOINTS[WaypointNames.WaterDamageDetails].displayName}
          >
            <GreyBorderedBox>
              <>
                <SummaryHeader headerText={'Water Related Damage Details'} />
                {homeownersIncidents.waterDamageSource && (
                  <Box pb={3} pt={3}>
                    <SummaryField label="What was the source of the water damage?">
                      <Typography>{initCap(homeownersIncidents.waterDamageSource)}</Typography>
                    </SummaryField>
                  </Box>
                )}
                {homeownersIncidents.whichIndoorSourceWaterDamaged && (
                  <Box pb={3} pt={3}>
                    <SummaryField label="What was the source of the indoor water damage?">
                      <Typography>
                        {initCap(homeownersIncidents.whichIndoorSourceWaterDamaged)}
                      </Typography>
                    </SummaryField>
                    {homeownersIncidents.indoorSourceWaterDamageOtherUnknownDesc && (
                      <SummaryField>
                        <Typography>
                          {homeownersIncidents.indoorSourceWaterDamageOtherUnknownDesc}
                        </Typography>
                      </SummaryField>
                    )}
                  </Box>
                )}
                {homeownersIncidents.whichIndoorHomeApplianceWaterDamaged && (
                  <Box pb={3} pt={3}>
                    <SummaryField label="Please select the related appliance.">
                      <Typography>
                        {initCap(homeownersIncidents.whichIndoorHomeApplianceWaterDamaged)}
                      </Typography>
                    </SummaryField>
                    {homeownersIncidents.indoorSourceHomeApplainceOtherUnknownDesc && (
                      <SummaryField>
                        <Typography>
                          {homeownersIncidents.indoorSourceHomeApplainceOtherUnknownDesc}
                        </Typography>
                      </SummaryField>
                    )}
                  </Box>
                )}
                {homeownersIncidents.whichIndoorBasementSourceWaterDamaged && (
                  <Box pb={3} pt={3}>
                    <SummaryField label="Please select the basement source.">
                      <Typography>
                        {initCap(homeownersIncidents.whichIndoorBasementSourceWaterDamaged)}
                      </Typography>
                    </SummaryField>
                    {homeownersIncidents.indoorBasementSourceOtherUnknownDesc && (
                      <SummaryField>
                        <Typography>
                          {homeownersIncidents.indoorBasementSourceOtherUnknownDesc}
                        </Typography>
                      </SummaryField>
                    )}
                  </Box>
                )}
                {homeownersIncidents.wasSupplyLineDamageCausedByPipesFreezing && (
                  <Box pb={3} pt={3}>
                    <SummaryField label="Was the incident a result of pipes freezing?">
                      <Typography>
                        {initCap(homeownersIncidents.wasSupplyLineDamageCausedByPipesFreezing)}
                      </Typography>
                    </SummaryField>
                  </Box>
                )}
                {homeownersIncidents.wasHouseOccupiedSupplyLineIncident && (
                  <Box pb={3} pt={3}>
                    <SummaryField label="Was the house occupied at the time of the incident?">
                      <Typography>
                        {initCap(homeownersIncidents.wasHouseOccupiedSupplyLineIncident)}
                      </Typography>
                    </SummaryField>
                  </Box>
                )}
                {homeownersIncidents.wasTherePowerOutageSupplyLineIncident && (
                  <Box pb={3} pt={3}>
                    <SummaryField label="Was there power outage prior to the damage?">
                      <Typography>
                        {initCap(homeownersIncidents.wasTherePowerOutageSupplyLineIncident)}
                      </Typography>
                    </SummaryField>
                  </Box>
                )}
                {homeownersIncidents.wasHeatMaintainedHomeSupplyLineIncident && (
                  <Box pb={3} pt={3}>
                    <SummaryField label="Was the heat maintained in the home?">
                      <Typography>
                        {initCap(homeownersIncidents.wasHeatMaintainedHomeSupplyLineIncident)}
                      </Typography>
                    </SummaryField>
                  </Box>
                )}
                {homeownersIncidents.whichOutdoorSourceWaterDamaged && (
                  <Box pb={3} pt={3}>
                    <SummaryField label="What was the source of the outdoor water damage?">
                      <Typography>
                        {initCap(homeownersIncidents.whichOutdoorSourceWaterDamaged)}
                      </Typography>
                    </SummaryField>
                    {homeownersIncidents.outdoorSourceWaterDamageOtherUnknownDesc && (
                      <SummaryField>
                        <Typography>
                          {homeownersIncidents.outdoorSourceWaterDamageOtherUnknownDesc}
                        </Typography>
                      </SummaryField>
                    )}
                  </Box>
                )}
                {homeownersIncidents.haveRecentRepairsDonePriorToIncident && (
                  <Box pb={3} pt={3}>
                    <SummaryField label="Have any recent repairs/service been done prior to this incident?">
                      <Typography>
                        {initCap(homeownersIncidents.haveRecentRepairsDonePriorToIncident)}
                      </Typography>
                    </SummaryField>
                  </Box>
                )}
                {!isEmptyArray(homeownersIncidents.whichDamagedAreasWereWaterDamaged) && (
                  <Box pb={3}>
                    <SelectedIncidents
                      selectedIncidents={joinMappedArray(
                        homeownersIncidents?.whichDamagedAreasWereWaterDamaged
                      )}
                      header="What was damaged? (Please select all that apply)"
                    />
                  </Box>
                )}
                {!isEmptyArray(homeownersIncidents.whichRoomsDamagedMainLevelWaterDamage) && (
                  <Box pb={3}>
                    <SelectedIncidents
                      selectedIncidents={joinMappedArray(
                        homeownersIncidents?.whichRoomsDamagedMainLevelWaterDamage
                      )}
                      header="Which rooms were damaged on the main level?"
                    />
                  </Box>
                )}
                {!isEmptyArray(homeownersIncidents.whichRoomsDamagedUpperLevelWaterDamage) && (
                  <Box pb={3}>
                    <SelectedIncidents
                      selectedIncidents={joinMappedArray(
                        homeownersIncidents?.whichRoomsDamagedUpperLevelWaterDamage
                      )}
                      header="Which rooms were damaged on the upper level?"
                    />
                  </Box>
                )}
                {!isEmptyArray(homeownersIncidents.whichRoomsDamagedBasementWaterDamage) && (
                  <Box pb={3}>
                    <SelectedIncidents
                      selectedIncidents={joinMappedArray(
                        homeownersIncidents?.whichRoomsDamagedBasementWaterDamage
                      )}
                      header="Which rooms were damaged in the basement?"
                    />
                  </Box>
                )}
                {!isEmptyArray(
                  homeownersIncidents.whichRoomsDamagedDetachedStructureWaterDamage
                ) && (
                  <Box pb={3}>
                    <SelectedIncidents
                      selectedIncidents={joinMappedArray(
                        homeownersIncidents?.whichRoomsDamagedDetachedStructureWaterDamage
                      )}
                      header="Which areas were damaged?"
                    />
                  </Box>
                )}
                {homeownersIncidents.hasAContractorBeenHired && (
                  <Box pb={3} pt={3}>
                    <SummaryField label="Has a contractor been selected?">
                      <Typography>
                        {initCap(homeownersIncidents.hasAContractorBeenHired)}
                      </Typography>
                    </SummaryField>
                  </Box>
                )}
              </>
            </GreyBorderedBox>
          </RegisterBreadcrumb>
        </PdfSection>
      )}
      <GreyBorderedBox>
        <AnyoneElseInjuredSummary
          injuryIncidents={injuryIncidents}
          submissionType={successState.submissionType || SubmissionTypes.Property}
        />
      </GreyBorderedBox>
      {(successState.officials || successState.witnesses) && (
        <PdfSection className={SUMMARY_PDF_ID}>
          <GreyBorderedBox>
            <OtherDetailsSummary
              officials={successState.officials}
              witnesses={successState.witnesses}
            />
          </GreyBorderedBox>
        </PdfSection>
      )}
      <GreyBorderedBox>
        <PropertyDamageDetailsSummary fixedPropertyIncidents={fixedPropertyIncidents} />
      </GreyBorderedBox>
      {userNote && (
        <PdfSection className={SUMMARY_PDF_ID}>
          <GreyBorderedBox>
            <AdditionalNotesSummary additionalNotes={userNote} />
          </GreyBorderedBox>
        </PdfSection>
      )}
    </>
  );
};
