import React from 'react';
import {Box, Typography, Grid} from '@material-ui/core';
import {SummaryField} from '../../common/Summary/components/SummaryField/SummaryField';
import {SummaryHeader} from '../../common/Summary/components/SummaryHeader/SummaryHeader';
import {initCap} from '../../../utils/utils';
import {
  RegisterBreadcrumb,
  WaypointNames,
  WAYPOINTS,
} from '../../common/RegisterBreadcrumb/RegisterBreadcrumb';

interface IncidentDetailsSummaryProps {
  incidentDescription?: string;
  selectedIncidents?: string;
  description?: string;
  rideshare?: string;
  delivery?: string;
  isInsuredBusiness?: string;
  deliveryBusinessName?: string;
  rideshareOrDelivery?: string;
}

export const IncidentDetailsSummary = (props: IncidentDetailsSummaryProps) => {
  const description =
    props.incidentDescription && props.selectedIncidents
      ? `${props.selectedIncidents}, ${props.incidentDescription}`
      : props.description;
  return (
    <RegisterBreadcrumb
      waypointName={WaypointNames.IncidentDetails}
      displayName={WAYPOINTS[WaypointNames.IncidentDetails].displayName}
    >
      <Box>
        <SummaryHeader headerText="Incident Details" />
        <Box pt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SummaryField>
                <Typography>{description || ''}</Typography>
              </SummaryField>
            </Grid>
            {props.rideshareOrDelivery && (
              <Grid item xs={12}>
                <SummaryField label="Was the vehicle being used for Rideshare or Delivery at the time of the incident?">
                  <Typography>{initCap(props.rideshareOrDelivery)}</Typography>
                </SummaryField>
              </Grid>
            )}
            {(props.rideshare || props.delivery) && (
              <>
                <Grid item xs={12}>
                  <SummaryField label="Was the vehicle being used for Rideshare at the time of the incident?">
                    <Typography>{initCap(props.rideshare || '')}</Typography>
                  </SummaryField>
                </Grid>

                <Grid item xs={12}>
                  <SummaryField label="Was the vehicle being used for Delivery at the time of the incident?">
                    <Typography>{initCap(props.delivery || '')}</Typography>
                  </SummaryField>
                </Grid>
                {props.isInsuredBusiness && (
                  <Grid item xs={12}>
                    <SummaryField label="Was the Delivery in the normal course of your Insured Business?">
                      <Typography>{initCap(props.isInsuredBusiness || '')}</Typography>
                    </SummaryField>
                  </Grid>
                )}
              </>
            )}
            {props.isInsuredBusiness === 'no' && (
              <Grid item xs={12}>
                <SummaryField label="What company was the delivery made for?">
                  <Typography>{initCap(props.deliveryBusinessName || '')}</Typography>
                </SummaryField>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </RegisterBreadcrumb>
  );
};
