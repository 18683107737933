import React, {ChangeEvent, createRef, ReactNode, useEffect} from 'react';
import {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {trackEvent} from '../../../services/analytics';
import {assignToUrl, clearSessionData, scrollToRef} from '../../../utils/utils';
import {SASelect} from '../../common/Select/Select';
import {navigate} from '@reach/router';
import {yupResolver} from '@hookform/resolvers';
import {Auth} from 'aws-amplify';
import Cookies from 'js-cookie';
import {autoPolicyNumberValidation, homeownersPolicyNumberValidation} from '../../../validations';
import {DynamicRadioList, RadioOption} from '../../common/DynamicRadioList/DynamicRadioList';
import {CustomerTypes, CUSTOMER_TYPES, Lobs} from '../../../commonTypes';
import {GettingStartedState, useUserAtomState} from '../../../atoms';
import {PhoneHyperlink} from '../../common/PhoneHyperlink/PhoneHyperlink';
import {AGENT_POLICY_SEARCH_ROUTE} from '../../../routes/paths';
import {useFeatureFlags} from '../../common/Providers/Providers';
import {SAText, SAColumns, SABox, SAButton, SAAlert, SACard} from '@saux/design-system-react';
import styled from 'styled-components';
import {BrowserVersionAlert} from '../../common/BrowserVersion/BrowserVersionAlert';
import {makeStyles} from '@material-ui/core';
import {RequiredStringSchema} from 'yup/lib/string';
import {AnyObject} from 'yup/lib/types';
import {Icon} from '../../common/Icon/Icon';
import {caPrivacyChoiceProdLink, caPrivacyChoiceStagingLink} from '../../../routes/links';
import {SAFooter} from '../../common/Footer/Footer';

interface LobPropsObj {
  [key: string]: {
    gettingStartedPath: string;
    formPath: string;
    implemented: boolean;
    policyNumberHelperText?: string;
    policyNumberValidation?: RequiredStringSchema<string | undefined, AnyObject>;
    policyTypeErrorMessage?: string;
    displayName: string;
  };
}

export const LOB_PROPS: LobPropsObj = {
  [Lobs.Auto]: {
    gettingStartedPath: '/policy-search',
    formPath: '/fnol-auto',
    implemented: true,
    policyNumberHelperText: 'ABC1234567, 1234567890 or 12345678AB',
    policyNumberValidation: autoPolicyNumberValidation(true),
    policyTypeErrorMessage:
      'The policy you entered is not an Auto policy. Please go back and select the correct claim type from the drop down that matches your policy.',
    displayName: 'Auto',
  },
  [Lobs.Homeowners]: {
    gettingStartedPath: '/policy-search',
    formPath: '/homeowners',
    implemented: true,
    policyNumberHelperText: 'ABC1234567, DF1234567, 1234567890 or 12345678AB',
    policyNumberValidation: homeownersPolicyNumberValidation(true),
    policyTypeErrorMessage:
      'The policy you entered is not a Homeowners policy. Please go back and select the correct claim type from the drop down that matches your policy.',
    displayName: 'Homeowners',
  },
  [Lobs.CommercialProperty]: {
    gettingStartedPath: 'https://www.stateauto.com/FileAClaim?ClaimCategory=CommercialProperty',
    formPath: '/',
    implemented: process.env.REACT_APP_FF_COMMERCIAL_PROPERTY === 'on',
    displayName: 'Commercial Property',
  },
  [Lobs.GeneralLiability]: {
    gettingStartedPath: 'https://www.stateauto.com/FileAClaim?ClaimCategory=GeneralLiability',
    formPath: '/',
    implemented: process.env.REACT_APP_FF_GENERAL_LIABILITY === 'on',
    displayName: 'General Liability',
  },
  [Lobs.WorkersCompensation]: {
    gettingStartedPath: 'https://www.interactclaims.com/claim/RTWINCLogin3.jsp',
    formPath: '/',
    implemented: process.env.REACT_APP_FF_WORKERS_COMP === 'on',
    displayName: "Workers' Compensation",
  },
  [Lobs.FarmAndRanch]: {
    gettingStartedPath: 'https://www.stateauto.com/FileAClaim?ClaimCategory=FarmAndRanch',
    formPath: '/',
    implemented: process.env.REACT_APP_FF_FARM_AND_RANCH === 'on',
    displayName: 'Farm and Ranch',
  },
};

const SACButton = styled(SAButton)`
  padding: 8px 22px !important;
  font-size: 0.9375rem !important;
`;

const SAColumnButton = styled(SAColumns)`
  justify-content: flex-end;
`;

const SAColumnCenter = styled(SAColumns)`
  justify-content: center;
`;

const SABoxContainer = styled(SABox)`
  margin-top: 64px;
  margin-bottom: 64px;
`;

const SABoxCenter = styled(SABox)`
  text-align: center;
  justify-content: center;
`;

const SABoxButton = styled(SABox)`
  display: flex;
  flex-direction: row-reverse;
`;

const SATextLink = styled(SAText)`
  text-decoration: underline;
  color: inherit;
`;

const SATextUnderlined = styled(SAText)`
  font-size: 14px;
  text-decoration: underline;
`;

const SATextPipe = styled(SAText)`
  margin-top: 17px;
  margin-left: 7px;
  margin-right: 7px;
`;

const SATypography = styled(SAText)`
  color: inherit;
`;

const useStyles = makeStyles(theme => ({
  fieldset: {
    border: '0 none',
  },
}));

export const PolicyNumberSchema = yup
  .string()
  .when('$lob', (lob: any) => LOB_PROPS[lob].policyNumberValidation || yup.string());

export const GettingStartedSchema = yup.object().shape({
  gettingStarted: yup.object().shape({
    customerType: yup.string().required(),
    lob: yup.string().required('Insurance type is required'),
  }),
});

export interface GettingStartedForm {
  gettingStarted: GettingStartedState;
}

export const GettingStarted = () => {
  const formMethods = useForm<GettingStartedForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    resolver: yupResolver(GettingStartedSchema),
  });

  const {register, handleSubmit, errors} = formMethods;

  const [userPersona, setUserPersona] = useState<string>('');
  const [userAtomState, setUserAtomState] = useUserAtomState();
  const [lob, setLob] = useState<string>('');
  const {featureFlags} = useFeatureFlags();

  const handleUserPersonaChange = (value: string) => {
    setUserPersona(value);
    trackEvent({category: 'Getting Started', label: CUSTOMER_TYPES[value]});
    clearSessionData();
  };

  const handleLobChange = (
    event: ChangeEvent<{name?: string | undefined; value: unknown}>,
    _child: ReactNode
  ) => {
    setLob(event.target.value as string);
  };

  const onSubmit = (data: GettingStartedForm) => {
    const lob = data.gettingStarted.lob;
    const {implemented, gettingStartedPath} = LOB_PROPS[lob];
    const userSession = Cookies.get('userSession');

    if (userPersona === CustomerTypes.SaAgent && implemented) {
      Cookies.set('data', JSON.stringify(data));
      Cookies.set('path', 'agent');

      if (userSession) {
        setUserAtomState({...userAtomState, ...data});
        navigate(AGENT_POLICY_SEARCH_ROUTE);
      } else {
        Cookies.set('after-login', 'true');

        Auth.federatedSignIn({
          customProvider: `${process.env.REACT_APP_COGNITO_CUSTOM_PROVIDER}`,
        }).catch(_err => {
          console.error('error signing in', _err);
        });
      }
    } else {
      Cookies.set('path', 'policy');
      if (implemented) {
        setUserAtomState({...userAtomState, ...data});
        navigate(gettingStartedPath);
      } else {
        assignToUrl(gettingStartedPath);
      }
    }
  };

  const PERSONA_OPTIONS: RadioOption[] = [
    {
      label: CUSTOMER_TYPES[CustomerTypes.SaAgent],
      value: CustomerTypes.SaAgent,
      testId: 'personaSelectorSaAgent',
    },
    {
      label: CUSTOMER_TYPES[CustomerTypes.SaCustomer],
      value: CustomerTypes.SaCustomer,
      testId: 'personaSelectorSaCustomer',
    },
    {
      label: CUSTOMER_TYPES[CustomerTypes.ThirdParty],
      value: CustomerTypes.ThirdParty,
      testId: 'personaSelectorThirdParty',
    },
    {
      label: CUSTOMER_TYPES[CustomerTypes.Guardian],
      value: CustomerTypes.Guardian,
      testId: 'personaSelectorGuardian',
    },
  ];

  const errorPanelRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (errorPanelRef.current) {
      scrollToRef(errorPanelRef);
    }
  }, [lob]);

  const unsupportedLobsDown =
    featureFlags?.FF_DCX_2085 &&
    LOB_PROPS[lob] &&
    !LOB_PROPS[lob].implemented &&
    lob !== Lobs.WorkersCompensation;

  const unsupportedLobs = [
    Lobs.GeneralLiability,
    Lobs.CommercialProperty,
    Lobs.FarmAndRanch,
  ].includes(lob as Lobs);

  const claimsOutage = featureFlags?.FF_DCX_2688;

  useEffect(() => {
    if (userAtomState?.gettingStarted?.customerType) {
      setUserPersona(userAtomState.gettingStarted.customerType);
    }
  }, []);

  const [alertDisplayed, setAlertDisplayed] = useState<boolean>(false);
  const onClick = (e: React.MouseEvent<HTMLElement>, link: string) => {
    e.preventDefault();
    window.open(link);
  };

  const callPrompt = (method: string) => {
    window.location.href = method;
  };

  const bannerRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (bannerRef.current) {
      scrollToRef(bannerRef);
    }
  }, [unsupportedLobsDown, unsupportedLobs]);

  const classes = useStyles();

  return (
    <>
      <BrowserVersionAlert setAlertDisplayed={setAlertDisplayed} />

      <SAColumnCenter columns={{xs: [11], md: [8]}}>
        <SABoxContainer>
          {alertDisplayed && (
            <SABox marginBottom={'large'}>
              <SAAlert severity="warning" title="Internet Explorer is no longer supported">
                <SATypography
                  type="standard"
                  text="Beginning June 15, 2022, Microsoft will no longer support Internet Explorer 11.
                  Please update your browser to "
                />
                <SATextLink
                  type="standard"
                  colorVariant="link"
                  text="Microsoft Edge"
                  onClick={e => onClick(e, 'https://www.microsoft.com/en-us/edge')}
                />
                <SATypography type="standard" text=" or " />
                <SATextLink
                  type="standard"
                  colorVariant="link"
                  text="Google Chrome"
                  onClick={e => onClick(e, 'https://www.google.com/intl/en_in/chrome/')}
                />
                <SATypography
                  type="standard"
                  text=" to ensure the best experience with State Auto applications."
                />
              </SAAlert>
            </SABox>
          )}

          <SACard variant="standard" title="Getting Started">
            {unsupportedLobsDown && (
              <SABox pb={'medium'}>
                <div ref={bannerRef} />
                <SAAlert
                  severity="error"
                  children={
                    <span id="claimTypeError" data-testid="claimTypeError">
                      We’re sorry, for {LOB_PROPS[lob]?.displayName} our online claim submission is
                      not working properly. Until it's fixed, we're here to take your claim 24/7 at{' '}
                      <PhoneHyperlink />, or you can email us at{' '}
                      <a href="mailto:claims@stateauto.com">claims@stateauto.com</a>.
                    </span>
                  }
                />
              </SABox>
            )}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
              <FormProvider {...formMethods}>
                <fieldset className={classes.fieldset}>
                  <legend>
                    <SABox pb={'medium'}>
                      <SAText>Please tell us about yourself</SAText>
                    </SABox>
                  </legend>
                  <DynamicRadioList
                    name="gettingStarted.customerType"
                    defaultValue={userAtomState?.gettingStarted?.customerType || ''}
                    options={PERSONA_OPTIONS}
                    onChange={handleUserPersonaChange}
                  />
                </fieldset>
              </FormProvider>
              <SABox pb={'14px'}>
                <SAText text={'What type of claim do you need to file?'} />
              </SABox>
              <SAColumns columns={{xs: [12, 12], sm: [6], lg: [4]}}>
                <SABox pb={unsupportedLobs ? '14px' : '100px'}>
                  <SASelect
                    name="gettingStarted.lob"
                    label="Insurance"
                    id="gettingStartedLob"
                    inputRef={register()}
                    defaultValue=""
                    error={errors.gettingStarted?.hasOwnProperty('lob')}
                    helperText={errors.gettingStarted?.lob?.message}
                    inputProps={{autoComplete: 'none'}}
                    onChange={handleLobChange}
                  >
                    <option value={Lobs.Auto}>{LOB_PROPS[Lobs.Auto].displayName}</option>
                    <option value={Lobs.Homeowners}>
                      {LOB_PROPS[Lobs.Homeowners].displayName}
                    </option>
                    <option value={Lobs.CommercialProperty}>
                      {LOB_PROPS[Lobs.CommercialProperty].displayName}
                    </option>
                    <option value={Lobs.GeneralLiability}>
                      {LOB_PROPS[Lobs.GeneralLiability].displayName}
                    </option>
                    <option value={Lobs.WorkersCompensation}>
                      {LOB_PROPS[Lobs.WorkersCompensation].displayName}
                    </option>
                    <option value={Lobs.FarmAndRanch}>
                      {LOB_PROPS[Lobs.FarmAndRanch].displayName}
                    </option>
                  </SASelect>
                </SABox>
                {unsupportedLobs && (
                  <SABox pb={'100px'}>
                    <div ref={bannerRef} />
                    <SAAlert
                      severity="warning"
                      children={
                        <span
                          id="unSupportedClaimTypeError"
                          data-testid="unSupportedClaimTypeError"
                        >
                          For {LOB_PROPS[lob]?.displayName} Call Customer CARE at <PhoneHyperlink />
                          .
                        </span>
                      }
                    />
                  </SABox>
                )}
                <SAColumnButton columns={{xs: [12, 12], sm: [6], lg: [4]}}>
                  <SABoxButton>
                    <SACButton
                      disabled={
                        unsupportedLobsDown ||
                        lob === '' ||
                        userPersona === '' ||
                        claimsOutage ||
                        unsupportedLobs
                      }
                      color="secondary"
                      label="Continue"
                      type="submit"
                      variant="medium"
                    />
                  </SABoxButton>
                </SAColumnButton>
              </SAColumns>
            </form>
          </SACard>
        </SABoxContainer>
      </SAColumnCenter>

      <SAColumnCenter columns={{xs: [11], md: [8]}}>
        <SABox>
          <SABoxCenter mb={'large'} mt={'large'}>
            <SAFooter />
          </SABoxCenter>
          <SABoxCenter
            mb={'xl'}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <SABox mr={'10px'} mb={'-4px'}>
              <Icon name="privacyChoice" />
            </SABox>
            <SATextUnderlined
              type="standard"
              colorVariant="link"
              text="Your California Privacy Choices"
              onClick={e =>
                onClick(
                  e,
                  process.env.NODE_ENV === 'production'
                    ? caPrivacyChoiceProdLink
                    : caPrivacyChoiceStagingLink
                )
              }
            />
            <SATextPipe type="paragraph" text="|" />
            <SATextUnderlined
              type="standard"
              colorVariant="link"
              text="Do Not Sell or Share My Personal Information (CA Residents Only)"
              onClick={() => callPrompt('javascript: utag.gdpr.showDoNotSellPrompt()')}
            />
          </SABoxCenter>
        </SABox>
      </SAColumnCenter>
    </>
  );
};
