import React from 'react';
import '../StickyBreadcrumbs/sticky.css';
import styled from 'styled-components';
import {SABox, SAText} from '@saux/design-system-react';
import {useGetReporterAtomState} from '../../../atoms';
import {suffixTypes} from '../ContactInfo/ContactInfoModal/ContactInfoModal';

const SAInfoBox = styled(SABox)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
`;

export const ReporterDisplayName = () => {
  const reporterAtomState = useGetReporterAtomState();

  return (
    <>
      {reporterAtomState?.contact?.firstName && (
        <SAInfoBox pb="medium">
          <SAText type="small" text="Reporter Name" colorVariant="primary" weight="bold" />
          <SAText
            type="standard"
            text={`${reporterAtomState.contact.firstName} ${reporterAtomState?.contact?.lastName} ${
              reporterAtomState?.contact?.suffix
                ? ' ' + suffixTypes[reporterAtomState?.contact?.suffix]
                : ''
            }`.trim()}
            alignment="right"
          />
        </SAInfoBox>
      )}
    </>
  );
};
