import React, {useState, useContext} from 'react';
import {Box, Typography, Grid, Divider} from '@material-ui/core';
import {ArrayField, useFieldArray, UseFieldArrayMethods, useFormContext} from 'react-hook-form';
import {
  DynamicContainer,
  DynamicContainerProps,
} from '../../common/DynamicContainer/DynamicContainer';
import {YesNoToggle} from '../../common/YesNoToggle/YesNoToggle';
import {BlueContainer} from '../../common/Containers/Containers';
import {
  stateValidation,
  zipCodeValidation,
  emailValidation,
  cityValidation,
  streetAddressValidation,
  OptionalPhoneNumbersSchema,
  firstNameValidation,
  lastNameValidation,
} from '../../../validations';
import * as yup from 'yup';
import {SelectTriage} from '../../common/SelectTriage/SelectTriage';
import {
  VehicleContactKeys,
  VehicleDetailsContext,
  VehicleTypes,
} from '../YourVehicleDetails/YourVehicleDetails';
import {YesNoUnk} from '../../common/YesNoUnk/YesNoUnk';
import {makePersonaCheck, personaSchemaSwitch} from '../../../utils/utils';
import {CustomerTypes} from '../../../commonTypes';
import {UserPersonaSwitch} from '../../common/UserPersonaSwitch/UserPersonaSwitch';
import {useManageContactOccurrences, useUserAtomState} from '../../../atoms';
import {ContactInfo} from '../../common/ContactInfo/ContactInfo';
import {ContactSections, FnolFormContact} from '../../common/ContactInfo/types';
import {SABox, SAColumns} from '@saux/design-system-react';

const basePassengerDetailsSchema = {
  ...OptionalPhoneNumbersSchema,
  passengerId: yup.string().required('Please select a passenger'),
  firstName: firstNameValidation().required('First Name is required'),
  lastName: lastNameValidation().required('Last Name is required'),
  autocompleteAddress: yup.string().nullable(),
  manualAddressEntry: yup.string().nullable(),
  address: streetAddressValidation().nullable(),
  city: cityValidation(true),
  state: stateValidation().nullable(),
  zipCode: zipCodeValidation(true),
  email: emailValidation(true),
  injured: yup.string().nullable(),
};

export const PassengerDetailsSchema = {
  wereTheAnyPassengers: yup.string().nullable(),
  additionalPassenger: yup.array().when('wereTheAnyPassengers', {
    is: 'yes',
    then: yup.array().of(
      yup.object().shape({
        ...basePassengerDetailsSchema,
      })
    ),
  }),
};

export const OtherPassengerDetailsSchema = {
  wereTheAnyPassengers: yup.string().nullable(),
  additionalPassenger: yup.array().when('wereTheAnyPassengers', {
    is: 'yes',
    then: yup.array().of(
      yup.object().shape({
        ...basePassengerDetailsSchema,
        injuryLevel: personaSchemaSwitch({
          is: (value: any) => makePersonaCheck(value, [CustomerTypes.Associate]),
          then: yup.string().when('injured', {
            is: (value: string) => value === 'yes',
            then: yup.string().required('Please select an injury level'),
            otherwise: yup.string().nullable(),
          }),
          otherwise: yup.string().nullable(),
        }),
      })
    ),
  }),
};

export const PassengerDetailsTestSchema = yup.object().shape({
  otherVehicles: yup.array().of(
    yup.object().shape({
      ...OtherPassengerDetailsSchema,
    })
  ),
  yourVehicles: yup.array().of(
    yup.object().shape({
      ...PassengerDetailsSchema,
    })
  ),
});

export interface AdditionalPassengerProps extends DynamicContainerProps {
  fieldArray: UseFieldArrayMethods<Record<string, any>, 'id'>;
  index: number;
  field: Partial<ArrayField<Record<string, any>, 'id'>>;
}

const AdditionalPassenger = ({fieldArray, index, addController}: AdditionalPassengerProps) => {
  const {register, errors} = useFormContext();
  const {remove, fields} = fieldArray;
  const {fieldIndex, parentFieldName, ownerType, setVehicleContact, removeVehicleContact} =
    useContext(VehicleDetailsContext);
  const isYourVehicle = ownerType === VehicleTypes.OWNER;
  const [passengerId, setPassengerId] = useState<string>('');
  const {removeContactOccurrences} = useManageContactOccurrences();
  const [injuredValue, setInjuredValue] = useState<string>('');
  const section = isYourVehicle
    ? ContactSections.InsuredVehiclePassenger
    : ContactSections.OtherVehiclePassenger;

  const onPassengerChange = (contact: FnolFormContact) => {
    const newPassengerId = contact?.fnolId || '';

    if (newPassengerId) {
      setVehicleContact && setVehicleContact(VehicleContactKeys.Passengers, newPassengerId);
    } else if (passengerId) {
      removeVehicleContact && removeVehicleContact(VehicleContactKeys.Passengers, passengerId);
    }

    setPassengerId(newPassengerId);
    setInjuredValue('');
  };

  const handleInjuredChange = (value: string) => {
    setInjuredValue(value);
  };

  const removePassenger = () => {
    removeVehicleContact && removeVehicleContact(VehicleContactKeys.Passengers, passengerId);
    removeContactOccurrences([{id: passengerId, sections: [section]}]);
    remove(index);
  };

  return (
    <DynamicContainer
      header={index === 0 ? 'Passenger Details' : `Passenger Details ${index + 1}`}
      displayAddButton={index === fields.length - 1}
      addButtonText="ADD PASSENGER"
      addController={addController}
      displayRemoveButton={fields.length !== 1}
      removeButtonText="REMOVE"
      removeController={removePassenger}
    >
      <Box pb={3} pt={2}>
        <ContactInfo
          section={section}
          parentFieldName={`${parentFieldName}[${fieldIndex}].additionalPassenger[${index}]`}
          selectProps={{
            name: 'passengerId',
            label: 'Passenger',
            id: `${parentFieldName}-[${(fieldIndex || 0) + 1}].additionalPassenger-${
              (index || 0) + 1
            }-passengerId`,
            inputProps: {
              'aria-label': `${parentFieldName}-[${(fieldIndex || 0) + 1}].additionalPassenger-${
                (index || 0) + 1
              }-Passenger`,
            },
          }}
          errorsObj={
            errors?.[parentFieldName || '']?.[fieldIndex || 0]?.additionalPassenger?.[index]
          }
          onContactChange={onPassengerChange}
          hiddenFields={{
            email: {render: true},
            phoneNumbers: {render: true},
            address: {render: true},
            city: {render: true},
            state: {render: true},
            zipCode: {render: true},
          }}
        />
        {passengerId && (
          <>
            <SABox pt="large">
              <Typography variant="body2">Was this passenger injured?</Typography>
            </SABox>
            <SABox pt="medium">
              <YesNoToggle
                name={`${parentFieldName}[${fieldIndex}].additionalPassenger[${index}].injured`}
                handleChange={handleInjuredChange}
                data-testid="additionalPassengerYesNoToggle"
                yesButtonTestId={`test--passenger-injured-yes-${index + 1}`}
                testId={`test-passenger-injured-no-${index + 1}`}
                defaultValue={injuredValue}
              />
            </SABox>
          </>
        )}
        {injuredValue === 'yes' && !isYourVehicle && (
          <UserPersonaSwitch
            ifPersonas={[CustomerTypes.Associate]}
            then={
              <SABox pt="medium">
                <SAColumns columns={{xs: [12], sm: [6], md: [4]}}>
                  <SelectTriage
                    label="Injury Level"
                    id="injuryLevel"
                    data-testid="injuryLevel"
                    name={`${parentFieldName}[${fieldIndex}].additionalPassenger[${index}].injuryLevel`}
                    error={errors?.[parentFieldName || '']?.[
                      fieldIndex || 0
                    ]?.additionalPassenger?.[index]?.hasOwnProperty('injuryLevel')}
                    helperText={
                      errors?.[parentFieldName || '']?.[fieldIndex || 0]?.additionalPassenger?.[
                        index
                      ]?.injuryLevel?.message
                    }
                    defaultValue=""
                    inputRef={register()}
                  />
                </SAColumns>
              </SABox>
            }
          />
        )}
      </Box>
    </DynamicContainer>
  );
};

export const PassengerDetails = () => {
  const {control} = useFormContext();
  const {fieldIndex, parentFieldName, ownerType, removeVehicleContact, vehicleContacts} =
    useContext(VehicleDetailsContext);
  const isYourVehicle = ownerType === VehicleTypes.OWNER;
  const [userAtomState] = useUserAtomState();
  const userPersona = userAtomState?.gettingStarted?.customerType;
  const [hasSelectedYes, setHasSelectedYes] = useState<boolean>(false);
  const {removeContactOccurrences} = useManageContactOccurrences();
  const fieldArray = useFieldArray({
    control,
    name: `${parentFieldName}[${fieldIndex}].additionalPassenger`,
  });

  const addAdditionalPassenger = () => {
    const newInjured = {
      passengerId: '',
      firstName: '',
      lastName: '',
      autocompleteAddress: '',
      manualAddressEntry: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      email: '',
      phoneNumbers: [{phoneNumber: '', phoneType: '', phoneExtension: '', verifiedNumber: 'false'}],
      injured: '',
    };

    fieldArray.append(
      userPersona === CustomerTypes.Associate && !isYourVehicle
        ? {...newInjured, injuryLevel: ''}
        : {...newInjured},
      false
    );
  };

  const handlePassengersChange = (value: string) => {
    const yesIsSelected = value === 'yes';

    setHasSelectedYes(yesIsSelected);

    if (yesIsSelected) {
      addAdditionalPassenger();
    } else {
      const section = isYourVehicle
        ? ContactSections.InsuredVehiclePassenger
        : ContactSections.OtherVehiclePassenger;

      if (vehicleContacts?.current) {
        removeContactOccurrences([
          ...vehicleContacts.current.passengerIds.map((id: string) => {
            return {id, sections: [section]};
          }),
        ]);
      }

      removeVehicleContact && removeVehicleContact(VehicleContactKeys.Passengers);
      fieldArray.remove();
    }
  };

  return (
    <Box>
      <Box pb={1}>
        <Typography variant="body1" data-testid="were-there-passengers">
          Were there passengers in the vehicle?
        </Typography>
      </Box>
      <Box pb={3}>
        <YesNoUnk
          name={`${parentFieldName}[${fieldIndex}].wereTheAnyPassengers`}
          onChange={handlePassengersChange}
          yesTestId="passengers-yes"
          noTestId="passengers-no"
          dontKnowTestId="passengers-dont-know"
        />
      </Box>
      {hasSelectedYes && (
        <BlueContainer mb={3}>
          {fieldArray.fields.map((field, index) => (
            <Box id={field.id} key={field.id} data-testid={`${parentFieldName}-${index + 1}`}>
              {index > 0 && (
                <Grid item xs={12}>
                  <Box pb={2}>
                    <Divider variant="middle" />
                  </Box>
                </Grid>
              )}
              <AdditionalPassenger
                fieldArray={fieldArray}
                index={index}
                field={field}
                addController={addAdditionalPassenger}
              />
            </Box>
          ))}
        </BlueContainer>
      )}
    </Box>
  );
};
