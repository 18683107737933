import React, {ChangeEvent, useEffect} from 'react';
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  Divider,
  FormGroup,
  useMediaQuery,
} from '@material-ui/core';
import {useState} from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';
import {DynamicContainer} from '../../common/DynamicContainer/DynamicContainer';
import {FormContainer} from '../../common/FormContainer/FormContainer';
import {YesNoToggle} from '../../common/YesNoToggle/YesNoToggle';
import {BlueContainer} from '../../common/Containers/Containers';
import {
  firstNameValidation,
  lastNameValidation,
  noSpecialCharacters,
  OptionalPhoneNumbersSchema,
} from '../../../validations';
import * as yup from 'yup';
import {
  RegisterBreadcrumb,
  WaypointNames,
  WAYPOINTS,
} from '../../common/RegisterBreadcrumb/RegisterBreadcrumb';
import {SATextField} from '../../common/TextField/TextField';
import {UserPersonaSwitch} from '../../common/UserPersonaSwitch/UserPersonaSwitch';
import {YesNoUnk} from '../../common/YesNoUnk/YesNoUnk';
import {CheckboxWithLabel} from '../../common/CheckboxWithLabel/CheckboxWithLabel';
import {useDefaultValues} from '../../../hooks';
import {MultiplePhoneNumbers} from '../../common/MultiplePhoneNumbers/MultiplePhoneNumbers';
import {AGENT_AND_ASSOCIATE_PERSONAS} from '../../../commonTypes';
import {suffixTypes} from '../../common/ContactInfo/ContactInfoModal/ContactInfoModal';
import {useFeatureFlags} from '../../common/Providers/Providers';

const useStyles = makeStyles(theme => ({
  whiteTextField: {
    background: theme.palette.common.white,
  },
}));

const otherDetailsScheme = yup.object().shape({
  ambulanceDepartmentName: noSpecialCharacters('Enter a valid department name', true),
  wasThereAPoliceReport: yup.string().nullable(),
  policeReportType: yup.string().when('wasThereAPoliceReport', {
    is: (wasThereAPoliceReport: any) => wasThereAPoliceReport === 'yes',
    then: yup.string().required('Please select the Report Type'),
    otherwise: yup.string().nullable(),
  }),
  policeDepartmentName: yup.string().when('wasThereAPoliceReport', {
    is: (wasThereAPoliceReport: any) => wasThereAPoliceReport === 'yes',
    then: yup
      .string()
      .max(255, 'Character Limit exceeded')
      .required('Please specify the Agency Name')
      .test('no-special-chars', 'Enter a valid department name', wasThereAPoliceReport =>
        /^[a-zA-Z0-9\s]+$/.test(wasThereAPoliceReport || '')
      ),
    otherwise: yup.string().nullable(),
  }),
  wasThereAFireReport: yup.string().nullable(),
  fireReportType: yup.string().when('wasThereAFireReport', {
    is: (wasThereAFireReport: any) => wasThereAFireReport === 'yes',
    then: yup.string().required('Please select the Report Type'),
    otherwise: yup.string().nullable(),
  }),
  fireDepartmentName: yup.string().when('wasThereAFireReport', {
    is: (wasThereAFireReport: any) => wasThereAFireReport === 'yes',
    then: yup
      .string()
      .max(255, 'Character Limit exceeded')
      .required('Please specify the Agency Name')
      .test('no-special-chars', 'Enter a valid department name', wasThereAFireReport =>
        /^[a-zA-Z0-9\s]+$/.test(wasThereAFireReport || '')
      ),
    otherwise: yup.string().nullable(),
  }),
});

const additionalWitnessSchema = yup.array().of(
  yup.object().shape({
    firstName: firstNameValidation().required('First Name is required'),
    lastName: lastNameValidation().required('Last Name is required'),
    suffix: yup.string().nullable(),
    ...OptionalPhoneNumbersSchema,
  })
);

export const OtherDetailsSchema = {
  otherDetails: yup.object().when('$acknowledgement', {
    is: (value: boolean | undefined) => value === true,
    then: otherDetailsScheme,
  }),
  additionalWitness: yup.array().when('$acknowledgement', {
    is: (value: boolean | undefined) => value === true,
    then: additionalWitnessSchema,
  }),
};

export const OtherDetailsTestSchema = yup.object().shape({
  otherDetails: otherDetailsScheme,
  additionalWitness: additionalWitnessSchema,
});

export const policeReportTypes: {[key: string]: string} = {
  auto_accident: 'Auto Accident',
  auto_theft: 'Auto Theft',
  auto_theft_recovery: 'Auto Theft Recovery',
  theft_burglary: 'Theft/Burglary',
  vandalism_civilOffense: 'Vandalism/Civil Offense',
  dui_report: 'DUI Report',
};

export const fireReportTypes: {[key: string]: string} = {
  auto_fire: 'Auto Fire',
  building_fire: 'Building Fire',
};

export const OtherDetails = () => {
  const {featureFlags} = useFeatureFlags();
  const formValues = useFormContext();
  const {register, control, errors} = formValues;

  const {getDefaultValue, setDefaultValue} = useDefaultValues();

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'additionalWitness',
  });

  const addController = () => {
    append({name: 'additionalWitness'});
  };

  const [hasCheckedPolice, setHasCheckedPolice] = useState(false);
  const [hasPoliceReport, setHasPoliceReport] = useState(false);
  const [hasCheckedAmbulance, setHasCheckedAmbulance] = useState(false);
  const [hasAmbulanceReport, setHasAmbulanceReport] = useState(false);
  const [hasCheckedFire, setHasCheckedFire] = useState(false);
  const [hasFireReport, setHasFireReport] = useState(false);

  const [hasCheckedNone, setHasCheckedNone] = useState(false);
  const handleCheckedNone = (checked: boolean) => {
    setHasCheckedNone(checked);
    setHasCheckedPolice(false);
    setHasPoliceReport(false);
    setHasCheckedAmbulance(false);
    setHasAmbulanceReport(false);
    setHasCheckedFire(false);
    setHasFireReport(false);
  };

  enum DeptTypes {
    Police = 'police',
    Ambulance = 'ambulance',
    Fire = 'fire',
  }

  const handleCheckDept = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const deptType = e.target.value;
    switch (deptType) {
      case DeptTypes.Police:
        setHasCheckedPolice(checked);
        if (!checked) {
          setHasPoliceReport(false);
        }
        break;
      case DeptTypes.Ambulance:
        setHasCheckedAmbulance(checked);
        if (!checked) {
          setHasAmbulanceReport(false);
        }
        break;
      case DeptTypes.Fire:
        setHasCheckedFire(checked);
        if (!checked) {
          setHasFireReport(false);
        }
        break;
    }
    setHasCheckedNone(false);
  };

  const handleToggleReport = (deptType: string, value: string) => {
    const yesIsSelected = value === 'yes';
    switch (deptType) {
      case DeptTypes.Police:
        setHasPoliceReport(yesIsSelected);
        break;
      case DeptTypes.Ambulance:
        setHasAmbulanceReport(yesIsSelected);
        break;
      case DeptTypes.Fire:
        setHasFireReport(yesIsSelected);
        break;
    }
  };

  const [hasSelectedYesWitness, setHasSelectedYesWitness] = useState(false);
  const handleToggleWitness = (value: string) => {
    const yesIsSelected = value === 'yes';
    if (yesIsSelected) {
      setHasSelectedYesWitness(true);

      if (fields.length === 0) {
        append({name: 'additionalWitness'});
      }
    } else {
      setHasSelectedYesWitness(false);
    }
  };

  useEffect(() => {
    if (!hasSelectedYesWitness) {
      for (let i = fields.length; i > 0; i--) {
        remove(i - 1);
      }
    }
  }, [hasSelectedYesWitness]);

  const classes = useStyles();

  const checkboxRow = useMediaQuery('(min-width:525px)');

  return (
    <RegisterBreadcrumb
      waypointName={WaypointNames.OtherDetails}
      displayName={WAYPOINTS[WaypointNames.OtherDetails].displayName}
    >
      <FormContainer header="Other Details">
        <Box>
          <Box pb={1}>
            <Typography variant="body2">Which first responders were at the scene?</Typography>
          </Box>
          <Box pb={4}>
            <FormGroup row={checkboxRow}>
              <CheckboxWithLabel
                name="otherDetails.hadFirstResponderPolice"
                formControlLabelProps={{
                  disabled: hasCheckedNone,
                  label: 'Police',
                }}
                checkboxProps={{
                  id: 'checkPolice',
                  onChange: (event, checked) => handleCheckDept(event, checked),
                  value: DeptTypes.Police,
                }}
              />
              <CheckboxWithLabel
                name="otherDetails.hadFirstResponderAmbulance"
                formControlLabelProps={{
                  disabled: hasCheckedNone,
                  label: 'Ambulance',
                }}
                checkboxProps={{
                  id: 'checkAmbulance',
                  onChange: (event, checked) => handleCheckDept(event, checked),
                  value: DeptTypes.Ambulance,
                }}
              />
              <CheckboxWithLabel
                name="otherDetails.hadFirstResponderFire"
                formControlLabelProps={{
                  disabled: hasCheckedNone,
                  label: 'Fire',
                }}
                checkboxProps={{
                  id: 'checkFire',
                  onChange: (event, checked) => handleCheckDept(event, checked),
                  value: DeptTypes.Fire,
                }}
              />
              <CheckboxWithLabel
                name="otherDetails.hadNoFirstResponders"
                formControlLabelProps={{
                  disabled: hasCheckedPolice || hasCheckedFire || hasCheckedAmbulance,
                  label: 'None',
                }}
                checkboxProps={{
                  id: 'checkNone',
                  onChange: (event, checked) => handleCheckedNone(checked),
                  value: 'none',
                }}
              />
            </FormGroup>
          </Box>
          {hasCheckedPolice && (
            <>
              <Box>
                <Box pb={1}>
                  <Typography variant="body2">Do you have a police report number?</Typography>
                </Box>
                <UserPersonaSwitch
                  ifPersonas={AGENT_AND_ASSOCIATE_PERSONAS}
                  then={
                    <Box pb={2} pt={1}>
                      <YesNoUnk
                        name="otherDetails.wasThereAPoliceReport"
                        onChange={(value: string) => handleToggleReport(DeptTypes.Police, value)}
                        yesTestId="test-yes-police-report"
                        noTestId="test-no-police-report"
                      />
                    </Box>
                  }
                  otherwise={
                    <Box pb={4}>
                      <YesNoToggle
                        name="otherDetails.wasThereAPoliceReport"
                        handleChange={(value: string) =>
                          handleToggleReport(DeptTypes.Police, value)
                        }
                        yesButtonTestId="test-yes-police-report"
                        testId="test-no-police-report"
                      />
                    </Box>
                  }
                />
                {hasPoliceReport && (
                  <Box pb={4}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <SATextField
                          name="otherDetails.policeReportType"
                          label="Police Report Type"
                          id="policeReportType"
                          inputRef={register()}
                          select
                          SelectProps={{native: true}}
                          InputLabelProps={{
                            'aria-labelledby': `policeReportType`,
                          }}
                          error={errors.otherDetails?.hasOwnProperty('policeReportType')}
                          helperText={errors.otherDetails?.policeReportType?.message}
                        >
                          <option aria-label="None" value="" />
                          {Object.keys(policeReportTypes).map((key: string) => (
                            <option value={key}>{policeReportTypes[key]}</option>
                          ))}
                        </SATextField>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <SATextField
                          name="otherDetails.policeReportNumber"
                          label="Police Report Number"
                          inputRef={register}
                          inputProps={{
                            'data-testid': 'test-police-report-number',
                            className: classes.whiteTextField,
                          }}
                          id="policeReportNumber"
                          InputLabelProps={{'aria-labelledby': 'policeReportNumber'}}
                          helperText="optional"
                          characterLimit={60}
                          autoFocus
                          multiline
                          showCharacterCount
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <SATextField
                          name="otherDetails.policeDepartmentName"
                          label="Department Name"
                          inputRef={register()}
                          inputProps={{className: classes.whiteTextField}}
                          id="policeDepartmentName"
                          data-testid="test-police-department-name"
                          InputLabelProps={{'aria-labelledby': 'policeDepartmentName'}}
                          error={errors.otherDetails?.hasOwnProperty('policeDepartmentName')}
                          helperText={
                            errors.otherDetails?.policeDepartmentName?.message ||
                            '255-Character Limit'
                          }
                          autoFocus
                          multiline
                          showCharacterCount
                          characterLimit={255}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            </>
          )}
          {hasCheckedAmbulance && (
            <Box>
              <Box pb={1}>
                <Typography variant="body2">Do you have an ambulance report?</Typography>
              </Box>
              <UserPersonaSwitch
                ifPersonas={AGENT_AND_ASSOCIATE_PERSONAS}
                then={
                  <Box pb={2} pt={1}>
                    <YesNoUnk
                      name="otherDetails.wasThereAnAmbulanceReport"
                      onChange={(value: string) => handleToggleReport(DeptTypes.Ambulance, value)}
                      yesTestId="test-yes-ambulance-report"
                      noTestId="test-no-ambulance-report"
                    />
                  </Box>
                }
                otherwise={
                  <Box pb={4}>
                    <YesNoToggle
                      name="otherDetails.wasThereAnAmbulanceReport"
                      handleChange={(value: string) =>
                        handleToggleReport(DeptTypes.Ambulance, value)
                      }
                      yesButtonTestId="test-yes-ambulance-report"
                      testId="test-no-ambulance-report"
                    />
                  </Box>
                }
              />
              {hasAmbulanceReport && (
                <Box pb={4}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <SATextField
                        name="otherDetails.ambulanceReportNumber"
                        label="Ambulance Report Number"
                        inputRef={register}
                        inputProps={{
                          'data-testid': 'test-ambulance-report-number',
                          className: classes.whiteTextField,
                        }}
                        id="ambulanceReportNumber"
                        InputLabelProps={{'aria-labelledby': 'ambulanceReportNumber'}}
                        helperText="optional"
                        characterLimit={60}
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <SATextField
                        name="otherDetails.ambulanceDepartmentName"
                        label="Department Name"
                        inputRef={register()}
                        inputProps={{className: classes.whiteTextField}}
                        id="ambulanceDepartmentName"
                        data-testid="test-ambulance-department-name"
                        InputLabelProps={{'aria-labelledby': 'ambulanceDepartmentName'}}
                        error={errors.otherDetails?.hasOwnProperty('ambulanceDepartmentName')}
                        helperText={
                          errors.otherDetails?.ambulanceDepartmentName?.message || 'optional'
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
          )}
          {hasCheckedFire && (
            <>
              <Box>
                <Box pb={1}>
                  <Typography variant="body2">Do you have a fire report?</Typography>
                </Box>
                <UserPersonaSwitch
                  ifPersonas={AGENT_AND_ASSOCIATE_PERSONAS}
                  then={
                    <Box pb={2} pt={1}>
                      <YesNoUnk
                        name="otherDetails.wasThereAFireReport"
                        onChange={(value: string) => handleToggleReport(DeptTypes.Fire, value)}
                        yesTestId="test-yes-fire-report"
                        noTestId="test-no-fire-report"
                      />
                    </Box>
                  }
                  otherwise={
                    <Box pb={4}>
                      <YesNoToggle
                        name="otherDetails.wasThereAFireReport"
                        handleChange={(value: string) => handleToggleReport(DeptTypes.Fire, value)}
                        yesButtonTestId="test-yes-fire-report"
                        testId="test-no-fire-report"
                      />
                    </Box>
                  }
                />
                {hasFireReport && (
                  <Box pb={4}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <SATextField
                          name="otherDetails.fireReportType"
                          label="Fire Report Type"
                          id="fireReportType"
                          inputRef={register()}
                          select
                          SelectProps={{native: true}}
                          InputLabelProps={{
                            'aria-labelledby': `fireReportType`,
                          }}
                          error={errors.otherDetails?.hasOwnProperty('fireReportType')}
                          helperText={errors.otherDetails?.fireReportType?.message}
                        >
                          <option aria-label="None" value="" />
                          {Object.keys(fireReportTypes).map((key: string) => (
                            <option value={key}>{fireReportTypes[key]}</option>
                          ))}
                        </SATextField>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <SATextField
                          name="otherDetails.fireReportNumber"
                          label="Fire Report Number"
                          inputRef={register}
                          inputProps={{
                            'data-testid': 'test-fire-report-number',
                            className: classes.whiteTextField,
                          }}
                          id="fireReportNumber"
                          InputLabelProps={{'aria-labelledby': 'fireReportNumber'}}
                          helperText="optional"
                          characterLimit={60}
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <SATextField
                          name="otherDetails.fireDepartmentName"
                          label="Department Name"
                          inputRef={register()}
                          inputProps={{className: classes.whiteTextField}}
                          id="fireDepartmentName"
                          data-testid="test-fire-department-name"
                          InputLabelProps={{'aria-labelledby': 'fireDepartmentName'}}
                          error={errors.otherDetails?.hasOwnProperty('fireDepartmentName')}
                          helperText={
                            errors.otherDetails?.fireDepartmentName?.message ||
                            '255-Character Limit'
                          }
                          autoFocus
                          multiline
                          showCharacterCount
                          characterLimit={255}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            </>
          )}
          <Box pb={1}>
            <Typography variant="body2">Were there any witnesses?</Typography>
          </Box>
          <UserPersonaSwitch
            ifPersonas={AGENT_AND_ASSOCIATE_PERSONAS}
            then={
              <Box pb={2} pt={1}>
                <YesNoUnk
                  name="otherDetails.wereThereAnyWitnesses"
                  onChange={handleToggleWitness}
                  yesTestId="test-yes-witness"
                  noTestId="test-no-witness"
                />
              </Box>
            }
            otherwise={
              <Box pb={4}>
                <YesNoToggle
                  name="otherDetails.wereThereAnyWitnesses"
                  handleChange={handleToggleWitness}
                  yesButtonTestId="test-yes-witness"
                  testId="test-no-witness"
                />
              </Box>
            }
          />
          {hasSelectedYesWitness && (
            <Box>
              <BlueContainer>
                <Box pb={1} fontSize={20} fontWeight="fontWeightBold">
                  Witness Information
                </Box>
                <Box pb={3}>
                  <Typography variant="body1">
                    If possible, provide a name and phone number for all witnesses.
                  </Typography>
                </Box>
                {fields.map((field, index) => (
                  <Box key={field.id}>
                    <>
                      {index > 0 && (
                        <Grid item xs={12}>
                          <Box pb={2}>
                            <Divider variant="middle" />
                          </Box>
                        </Grid>
                      )}
                    </>
                    <DynamicContainer
                      header={index === 0 ? 'Witness' : `Witness ${index + 1}`}
                      addButtonText="ADD WITNESS"
                      removeButtonText="REMOVE"
                      addController={addController}
                      displayAddButton={index === fields.length - 1}
                      displayRemoveButton={fields.length !== 1}
                      removeDataTestId={`test-remove-button-${index}`}
                      removeController={() => {
                        remove(index);
                      }}
                    >
                      <Box key={field.id} pb={3} pt={1}>
                        <Grid container spacing={3} justify="flex-start">
                          <Grid item xs={12} sm={4}>
                            <SATextField
                              name={`additionalWitness[${index}].firstName`}
                              label="First Name"
                              inputRef={register()}
                              inputProps={{className: classes.whiteTextField}}
                              characterLimit={30}
                              id={`firstName-${index}`}
                              InputLabelProps={{'aria-labelledby': `firstName-${index}`}}
                              onChange={(e: any) =>
                                setDefaultValue(
                                  `additionalWitness[${field.id || ''}].firstName`,
                                  e.target.value
                                )
                              }
                              defaultValue={getDefaultValue(
                                `additionalWitness[${field.id || ''}].firstName`
                              )}
                              error={errors.additionalWitness?.[index]?.hasOwnProperty('firstName')}
                              helperText={errors.additionalWitness?.[index]?.firstName?.message}
                              autoFocus
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <SATextField
                              name={`additionalWitness[${index}].lastName`}
                              label="Last Name"
                              inputRef={register()}
                              inputProps={{className: classes.whiteTextField}}
                              characterLimit={30}
                              id={`lastName-${index}`}
                              InputLabelProps={{'aria-labelledby': `lastName-${index}`}}
                              onChange={(e: any) =>
                                setDefaultValue(
                                  `additionalWitness[${field.id || ''}].lastName`,
                                  e.target.value
                                )
                              }
                              defaultValue={getDefaultValue(
                                `additionalWitness[${field.id || ''}].lastName`
                              )}
                              error={errors.additionalWitness?.[index]?.hasOwnProperty('lastName')}
                              helperText={errors.additionalWitness?.[index]?.lastName?.message}
                            />
                          </Grid>
                          {featureFlags.FF_DCARE_6770 && (
                            <Grid item xs={12} sm={4}>
                              <SATextField
                                name={`additionalWitness[${index}].suffix`}
                                label="Suffix"
                                inputRef={register}
                                defaultValue={getDefaultValue(
                                  `additionalWitness[${field.id || ''}].suffix`
                                )}
                                onChange={(e: any) =>
                                  setDefaultValue(
                                    `additionalWitness[${field.id || ''}].suffix`,
                                    e.target.value
                                  )
                                }
                                select
                                SelectProps={{native: true}}
                                id={`suffix-${index}`}
                                InputLabelProps={{'aria-labelledby': `suffix-${index}`}}
                                error={errors.additionalWitness?.[index]?.hasOwnProperty('suffix')}
                                helperText={'optional'}
                              >
                                <option aria-label="None" value="" />
                                {Object.keys(suffixTypes).map((key: string) => (
                                  <option value={key}>{suffixTypes[key]}</option>
                                ))}
                              </SATextField>
                            </Grid>
                          )}
                          <Grid item xs={12} sm={6}>
                            <MultiplePhoneNumbers
                              scope={`additionalWitness[${index}]`}
                              id={`additional-witness-${index + 1}-phone`}
                              errorProperty={errors.additionalWitness?.[index]?.phoneNumbers}
                              mandatoryFields={[]}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </DynamicContainer>
                  </Box>
                ))}
              </BlueContainer>
            </Box>
          )}
        </Box>
      </FormContainer>
    </RegisterBreadcrumb>
  );
};
