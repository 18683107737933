import React from 'react';
import {Box, Typography} from '@material-ui/core';
import {useState} from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';
import {FormContainer} from '../../common/FormContainer/FormContainer';
import * as yup from 'yup';
import {
  zipCodeValidation,
  cityValidation,
  streetAddressValidation,
  emailValidation,
  OptionalPhoneNumbersSchema,
  stateValidation,
  propertyDamageDescriptionValidation,
  firstNameValidation,
  lastNameValidation,
} from '../../../validations';
import {
  RegisterBreadcrumb,
  WaypointNames,
  WAYPOINTS,
} from '../../common/RegisterBreadcrumb/RegisterBreadcrumb';
import {UserPersonaSwitch} from '../../common/UserPersonaSwitch/UserPersonaSwitch';
import {BaseSwitch} from '../../common/BaseSwitch/BaseSwitch';
import {YesNoUnk} from '../../common/YesNoUnk/YesNoUnk';
import {YesNoToggle} from '../../common/YesNoToggle/YesNoToggle';
import {
  PropertyDamageForm,
  PropertyForms,
} from '../../common/PropertyDamageForm/PropertyDamageForm';
import {AGENT_AND_ASSOCIATE_PERSONAS, ContactTypes, CustomerTypes} from '../../../commonTypes';
import {useRemoveSectionOccurrences} from '../../../atoms';
import {ContactSections} from '../../common/ContactInfo/types';

const schema = yup.array().of(
  yup.object().shape({
    ownerId: yup.string().required('Please select the owner of the property'),
    firstName: yup.string().when('radioSelection', {
      is: (value: string) => {
        return value === ContactTypes.Person;
      },
      then: firstNameValidation().required('First Name is required'),
    }),
    lastName: yup.string().when('radioSelection', {
      is: (value: string) => {
        return value === ContactTypes.Person;
      },
      then: lastNameValidation().required('Last Name is required'),
    }),
    companyName: yup.string().when('radioSelection', {
      is: (value: string) => {
        return value === ContactTypes.Company;
      },
      then: yup.string().required('Company Name is required'),
    }),
    propertyTypeSelection: yup.string().required('Property type is required'),
    otherProperty: yup.string().when('propertyTypeSelection', {
      is: (value: string) => {
        return value === 'other';
      },
      then: yup.string().required('Property type is required'),
    }),
    damage: propertyDamageDescriptionValidation().required('Damage description is required'),
    propertyAddress: yup.object().shape({
      autocompleteAddress: yup.string().nullable(),
      manualAddressEntry: yup.string().nullable(),
      address: streetAddressValidation().nullable(),
      city: cityValidation(true),
      state: stateValidation().nullable(),
      zipCode: zipCodeValidation(true),
    }),
    ownerAddress: yup.object().shape({
      autocompleteAddress: yup.string().nullable(),
      manualAddressEntry: yup.string().nullable(),
      address: streetAddressValidation().nullable(),
      city: cityValidation(true),
      state: stateValidation().nullable(),
      zipCode: zipCodeValidation(true),
    }),
    email: emailValidation(true),
    ...OptionalPhoneNumbersSchema,
  })
);

export const PropertyDamageDetailsSchema = {
  additionalProperty: yup.array().when('$acknowledgement', {
    is: (value: boolean | undefined) => value === true,
    then: schema,
  }),
};

export const PropertyDamageDetailsTestSchema = yup.object().shape({
  additionalProperty: schema,
});

export interface PropertyDamageDetailsProps {
  isOtherProperty?: boolean;
}

export const PropertyDamageDetails = (props: PropertyDamageDetailsProps) => {
  const {control} = useFormContext();
  const [displayPropertyDamageForm, setDisplayPropertyDamageForm] = useState<boolean>(false);
  const fieldArray = useFieldArray({
    control,
    name: PropertyForms.AdditionalProperty,
  });
  const removeAdditionalPropertyOccurrences = useRemoveSectionOccurrences(
    ContactSections.OtherPropertyOwner
  );

  const addAdditionalProperty = () => {
    const newProperty = {
      propertyTypeSelection: '',
      otherProperty: '',
      damage: '',
      propertyAddress: {
        autocompleteAddress: '',
        manualAddressEntry: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
      },
      ownerId: '',
      radioSelection: ContactTypes.Person,
      firstName: '',
      lastName: '',
      companyName: '',
      email: '',
      phoneNumbers: [{phoneNumber: '', phoneType: '', phoneExtension: '', verifiedNumber: 'false'}],
      ownerAddressSameAsProperty: '',
      ownerAddress: {
        autocompleteAddress: '',
        manualAddressEntry: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
      },
    };

    fieldArray.append({...newProperty}, false);
  };

  const handleToggleChange = (value: string) => {
    const yesIsSelected = value === 'yes';

    setDisplayPropertyDamageForm(yesIsSelected);

    if (yesIsSelected) {
      addAdditionalProperty();
    } else {
      fieldArray.remove();
      removeAdditionalPropertyOccurrences();
    }
  };

  return (
    <RegisterBreadcrumb
      waypointName={WaypointNames.PropertyDamageDetails}
      displayName={WAYPOINTS[WaypointNames.PropertyDamageDetails].displayName}
    >
      <FormContainer header="Property Damage Details">
        <Box>
          <Box pb={1}>
            <BaseSwitch
              matches={props.isOtherProperty}
              then={<Typography variant="body2">Was there any other property damaged?</Typography>}
              otherwise={
                <Typography variant="body2">
                  Was there any property damaged? (i.e. - mailboxes, guardrails, light poles,
                  buildings, fire hydrants etc)
                </Typography>
              }
            />
          </Box>
          <Box pb={4} pt={1}>
            <UserPersonaSwitch
              ifPersonas={[...AGENT_AND_ASSOCIATE_PERSONAS, CustomerTypes.Guardian]}
              then={
                <YesNoUnk
                  name="wasThePropertyDamaged"
                  yesTestId="propertyDmg"
                  noTestId="noPropertyDmg"
                  dontKnowTestId="unkPropertyDmg"
                  onChange={handleToggleChange}
                />
              }
              otherwise={
                <YesNoToggle name="wasThePropertyDamaged" handleChange={handleToggleChange} />
              }
            />
          </Box>
          {displayPropertyDamageForm && (
            <PropertyDamageForm
              formName={PropertyForms.AdditionalProperty}
              fieldArray={fieldArray}
              addAdditionalProperty={addAdditionalProperty}
            />
          )}
        </Box>
      </FormContainer>
    </RegisterBreadcrumb>
  );
};
