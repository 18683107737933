import React from 'react';
import InputMask from 'react-input-mask';
import {Memoize} from '../Memoize/Memoize';
import {SATextField, SATextFieldProps} from '../TextField/TextField';
import {Grid, makeStyles} from '@material-ui/core';
import {SASelect} from '../Select/Select';
import {PhoneTypes, ALL_PHONE_TYPES} from '../../../commonTypes';

interface PhoneTypeProps {
  showPhoneType: boolean;
  name: string;
  defaultValue?: string;
  value?: string;
  phoneTypes: PhoneTypes[];
  onChange?: (e: any) => void;
  key?: string;
  hasError?: boolean;
  helperText?: string;
  testId?: string;
  id?: string;
  inputRef?: React.Ref<any>;
}

interface PhoneExtensionProps {
  showPhoneExtension: boolean;
  InputLabelProps?: any;
  inputProps?: any;
  hasError: boolean | undefined;
  helperText: string;
  name: string;
  value?: string;
  defaultValue?: string;
  testId?: string;
  id?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  inputRef?: any;
  label: string;
}

const phoneOptions = {
  [PhoneTypes.Mobile]: {
    type: 'mobile',
    label: 'Mobile',
  },
  [PhoneTypes.Work]: {
    type: 'work',
    label: 'Work',
  },
  [PhoneTypes.Home]: {
    type: 'home',
    label: 'Home',
  },
};

const useStyles = makeStyles(theme => ({
  responsiveField: {
    width: '100%',
    '&:first-of-type': {
      marginBottom: '2px',
    },
    marginBottom: '15px',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
}));

export interface PhoneInputProps {
  InputLabelProps?: any;
  inputProps?: any;
  hasError: boolean | undefined;
  helperText: string;
  name: string;
  value?: string;
  defaultValue?: string;
  id?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  inputRef?: any;
  label?: string;
  phoneTypeProps?: PhoneTypeProps;
  phoneExtensionProps?: PhoneExtensionProps;
}

export const PhoneInput = (props: PhoneInputProps & SATextFieldProps) => {
  const classes = useStyles();

  return (
    <Memoize renderOn={[props.hasError, props.value, props.inputRef, props.defaultValue]}>
      <Grid container spacing={1} justifyContent="space-between">
        <Grid
          item
          xs={12}
          sm={props?.phoneTypeProps?.showPhoneType ? 5 : 12}
          className={classes.responsiveField}
        >
          <InputMask
            mask="(999) 999-9999"
            value={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
            defaultValue={props.defaultValue}
          >
            {() => (
              <SATextField
                {...props}
                id={props.id}
                name={props.name}
                inputRef={props.inputRef}
                label={props.label || 'Phone Number'}
                error={props.hasError}
                helperText={props.helperText}
                InputLabelProps={props.InputLabelProps}
                inputProps={props.inputProps}
                defaultValue={props.defaultValue}
              />
            )}
          </InputMask>
        </Grid>
        {props?.phoneTypeProps?.showPhoneType && (
          <Grid item xs={12} sm={3} className={classes.responsiveField}>
            <SASelect
              label="Phone Type"
              name={props.phoneTypeProps.name}
              inputRef={props.phoneTypeProps.inputRef}
              inputProps={{'aria-label': props.phoneTypeProps.id || 'phoneType'}}
              id={props.phoneTypeProps.id || 'phoneType'}
              defaultValue={props.phoneTypeProps?.defaultValue}
              value={(props.phoneTypeProps && props.phoneTypeProps?.value) as PhoneTypes}
              key={props.phoneTypeProps?.key}
              onChange={(e: any) => {
                props?.phoneTypeProps?.onChange && props.phoneTypeProps.onChange(e);
              }}
              error={props.phoneTypeProps.hasError}
              helperText={props.phoneTypeProps.helperText}
              data-testId={props.phoneTypeProps.testId}
            >
              {props?.phoneTypeProps?.phoneTypes
                ? props?.phoneTypeProps?.phoneTypes.map(phoneType => (
                    <option value={phoneOptions[phoneType].type} key={phoneType}>
                      {phoneOptions[phoneType].label}
                    </option>
                  ))
                : ALL_PHONE_TYPES.map(phoneType => (
                    <option value={phoneOptions[phoneType].type} key={phoneType}>
                      {phoneOptions[phoneType].label}
                    </option>
                  ))}
            </SASelect>
          </Grid>
        )}
        {props.phoneExtensionProps?.showPhoneExtension && (
          <Grid
            item
            xs={12}
            sm={props.phoneTypeProps?.showPhoneType ? 4 : 12}
            className={classes.responsiveField}
          >
            <SATextField
              {...props.phoneExtensionProps}
              id={props.phoneExtensionProps?.id}
              name={props.phoneExtensionProps?.name}
              inputRef={props.phoneExtensionProps?.inputRef}
              label={props.phoneExtensionProps.label || 'Phone Extension'}
              value={props.phoneExtensionProps?.value}
              onChange={props.phoneExtensionProps?.onChange}
              onBlur={props.phoneExtensionProps?.onBlur}
              error={props.phoneExtensionProps?.hasError}
              helperText={props.phoneExtensionProps?.helperText || 'optional'}
              data-testId={props.phoneExtensionProps?.testId}
              InputLabelProps={props.phoneExtensionProps?.InputLabelProps}
              inputProps={props.phoneExtensionProps?.inputProps}
              defaultValue={props.phoneExtensionProps?.defaultValue}
            />
          </Grid>
        )}
      </Grid>
    </Memoize>
  );
};
