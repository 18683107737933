import {STPModelResponse} from '../../../../services';
import {VehicleCopartProps} from '../../Copart/types';

export interface LabelValue {
  label: string;
  value: boolean | string;
}

export interface StateProps {
  claimNumber: string;
  publicID?: string;
  lobs?: Lobs;
  policy?: Policy;
  adjusterDetails?: Adjuster;
  mainContact?: Contact;
  lossDate?: string;
  customerType: string;
  officials?: Official[];
  witnesses?: Contact[];
  quickEstimateVehicles?: VehicleIncident[];
  vehiclesToShowStaff?: VehicleIncident[];
  lossLocation?: Address;
  assignmentLossType?: string;
  triageLevel?: string;
  incidentDescription?: string;
  selectedIncidents?: string;
  submissionType?: SubmissionTypes;
  adjusterNote?: Notes[];
  contacts?: Contact[];
  description?: string;
  timeoutOccurred?: boolean;
  documents?: any[];
  isCATClaim?: boolean;
  reviewLossHistory?: boolean;
  noticeOnly?: boolean;
  lossCause?: string;
  otherDetails?: any;
  howReported?: string;
  claimPreference?: {
    isEpayOptOut?: string;
  };
  stpResult?: STPModelResponse;
  isStpUpdateCallFailed?: boolean;
  formData?: {
    gettingStarted?: {
      howReported?: string;
      phoneNumbers: PhoneNumber[];
    };
    generalDetails?: {
      lossLocation?: Address;
      lossDate?: string;
      dateOfLossUnknown?: boolean;
      fraudLanguage?: string;
      lawsuitClaim?: boolean;
      lossTime?: string;
      lossTimezone?: string;
    };
    incidentDetails?: {
      incidents?: LabelValue[];
      firstIncident?: LabelValue;
      natureOfLoss?: string;
      lossCause?: string;
      propertyTypes?: LabelValue[];
      whereDamageToHomeIs?: LabelValue[];
      wasDamageCausedByTreeOrLimb?: string;
      isTreeLimbOnTheRoof?: string;
      whatWasDamagedToTheExteriorOfTheHome?: LabelValue[];
      haveTemporaryRepairsBeenMadeToTheRoof?: string;
      whatWasDamagedToTheInteriorOfTheHome?: LabelValue[];
      whichRoomsWereDamagedOnTheFirstFloor?: LabelValue[];
      whichRoomsWereDamagedOnTheSecondFloor?: LabelValue[];
      whichRoomsWereDamagedInTheBasement?: LabelValue[];
      hasAContractorBeenHired?: string;
      fireDamageDetailsRadioGroup?: string;
      whichAreasHaveSmokeDamageOnly?: LabelValue[];
      whichRoomsDamagedMainLevelAreaSmokeDamageOnly?: LabelValue[];
      whichRoomsDamagedUpperLevelAreaSmokeDamageOnly?: LabelValue[];
      whichRoomsDamagedBasementAreaSmokeDamageOnly?: LabelValue[];
      whichAreasWereDamagedByFire?: LabelValue[];
      whichRoomsDamagedMainLevelAreaDamagedByFire?: LabelValue[];
      whichRoomsDamagedUpperLevelAreaDamagedByFire?: LabelValue[];
      whichRoomsDamagedBasementAreaDamagedByFire?: LabelValue[];
      waterDamageSource?: string;
      whichIndoorSourceWaterDamaged?: string;
      indoorSourceWaterDamageOtherUnknownDesc?: string;
      whichIndoorHomeApplianceWaterDamaged?: string;
      indoorSourceHomeApplainceOtherUnknownDesc?: string;
      whichIndoorBasementSourceWaterDamaged?: string;
      indoorBasementSourceOtherUnknownDesc?: string;
      wasSupplyLineDamageCausedByPipesFreezing?: string;
      wasHouseOccupiedSupplyLineIncident?: string;
      wasTherePowerOutageSupplyLineIncident?: string;
      wasHeatMaintainedHomeSupplyLineIncident?: string;
      whichOutdoorSourceWaterDamaged?: string;
      outdoorSourceWaterDamageOtherUnknownDesc?: string;
      haveRecentRepairsDonePriorToIncident?: string;
      whichDamagedAreasWereWaterDamaged?: LabelValue[];
      whichRoomsDamagedMainLevelWaterDamage?: LabelValue[];
      whichRoomsDamagedUpperLevelWaterDamage?: LabelValue[];
      whichRoomsDamagedBasementWaterDamage?: LabelValue[];
      whichRoomsDamagedDetachedStructureWaterDamage?: LabelValue[];
      otherDetails?: string | undefined;
      rideshareOrDelivery?: string | undefined;
      rideshare?: string | undefined;
      delivery?: string | undefined;
      isInsuredBusiness?: string | undefined;
      deliveryBusinessName?: string | undefined;
      isPropertyHabitable?: string | undefined;
      contractor?: {
        contractorName?: string;
        phoneNumbers?: PhoneNumber[];
        email?: string;
        address?: string;
        zipCode?: string;
        city?: string;
        state?: string;
      };
      emergencyServices?: LabelValue[] | undefined;
    };
    insuredContactInformation?: InsuredContact;
    vehiclePreferences?: (VehicleCopartProps & VehicleIncident)[];
    noticeOnly?: boolean;
  };
  showUninhabitableSummary?: boolean | undefined;
}

export interface Notes {
  body?: string;
  subject?: string;
}

export interface Lobs {
  personalAuto?: Lob;
  commercialAuto?: Lob;
  homeowners?: Lob;
  homeowner?: Lob;
}

export enum SubmissionTypes {
  Collision = 'collision',
  Property = 'property',
}

export interface Lob {
  vehicleIncidents?: VehicleIncident[];
  injuryIncidents?: InjuryIncident[];
  fixedPropertyIncidents?: FixedPropertyIncident[];
  propertyIncidents?: FixedPropertyIncident[];
  vehicles?: Vehicle[];
  locations?: Location[];
}

export interface VehicleIncident {
  publicID?: string;
  vehicle?: Vehicle;
  impactPoints?: ImpactPoint[];
  driver?: Contact;
  owner?: Contact;
  passengers?: Contact[];
  copartAlternateContact?: Contact;
  airbagsDeployed?: boolean;
  vehicleDriveable?: boolean;
  isYours?: boolean;
  vehicleLocation?: string;
  locationAddress?: Address;
  primaryImpactPoints?: string;
  quickEstimateEligible?: boolean;
  quickEstimateRequested?: string;
  staffAppraiserEligible?: boolean;
  copartAppraiserEligible?: boolean;
  inPossession?: boolean;
  locationType?: string;
  photoEstimate?: boolean;
  vehicleInCustomerPossession?: string;
  vehicleLocationSelection?: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  phoneExtension?: string;
  suffix?: string;
  companyName?: string;
  stpModelEligible?: boolean;
}

export interface Vehicle {
  publicID?: string;
  make?: string;
  model?: string;
  vIN?: string;
  year?: number | string;
  country?: string;
  policyVehicle?: boolean;
  style?: string;
  coverages?: Coverage[];
  simplifiedCoverages?: SimplifiedCoverage;
}

export interface SimplifiedCoverage {
  collision: Coverage[];
  comprehensive: Coverage[];
  rental: Coverage[];
  endorsementRental: Coverage[];
}

export interface ImpactPoint {
  label: string;
  value: boolean;
}

export interface InjuryIncident {
  publicID?: string;
  injured?: Contact;
  generalInjuryType?: string;
  lossPartyType?: string;
  receiveTreatment?: boolean;
  injuryType?: string;
}

export interface Address {
  publicID?: string;
  displayName?: string;
  addressLine1?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  addressType?: string;
}

export interface Policy {
  lobs?: Lobs;
  policyType?: string;
  policyState?: string;
  coverages?: Coverage[];
  effectiveDate?: string;
  expirationDate?: string;
  policyInceptionDate?: string;
  policyNumber?: string;
  premiumPaid?: boolean;
  producerCode?: string;
  publicID?: string;
  status?: string;
}

export interface Location {
  locationAddress?: Address;
}

export interface Adjuster {
  adjusterName?: string;
  adjusterEmail?: string;
  adjusterPhoneNumber?: string;
  assignedGroup?: string;
  userName?: string;
}

export interface Contact {
  publicID?: string;
  displayName?: string;
  firstName?: string;
  lastName?: string;
  suffix?: string;
  subtype?: string;
  primaryAddress?: Address;
  primaryPhoneType?: string;
  primaryPhoneExtension?: string;
  cellNumber?: string;
  emailAddress1?: string;
  contactType?: string;
  insuranceProvider?: string;
  policyOrClaimNumber?: string;
  injured?: boolean;
  workNumber?: string;
  contactRoles?: string[];
  contactName?: string;
  homeNumber?: string;
}

export interface PhoneNumber {
  phoneNumber?: string;
  phoneType?: string;
  phoneExtension?: string;
}

export interface InsuredContact {
  contactID?: string;
  email?: string;
  insuredName?: string;
  firstName?: string;
  lastName?: string;
  suffix?: string;
  phoneNumbers?: PhoneNumber[];
  relationshipToInsured?: string;
}

export interface FixedPropertyIncident {
  publicID: string;
  description?: string | undefined;
  propertyLossParty?: string | undefined;
  propertyAddress: Address;
  propertyOwner: Contact;
  propertyTypeSelection?: string | undefined;
  otherProperty?: string | undefined;
  damageDescription?: string | undefined;
  extDamagetxt?: string | undefined;
  ownerAddressSameAsProperty: boolean;
}

export interface Official {
  publicID?: string;
  officialReportType?: string;
  officialType?: string;
  officialName?: string;
  reportNumber?: string;
}

export interface Limit {
  amount: number;
  currency: string;
}

export interface Coverage {
  publicID?: string;
  name?: string;
  code?: string;
  incidentLimit?: Limit;
  exposureLimit?: Limit;
  deductible?: Limit;
}

export interface FormVehicleData {
  firstName?: string;
  lastName?: string;
  suffix?: string;
  companyName?: string;
  phoneNumber?: string;
  phoneExtension?: string;
  vehicleInCustomerPossession?: string;
  vehicleLocationSelection?: string;
}
